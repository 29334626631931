<template>
  <div class="theme bg-pink">
    <div class="theme-landing">
      <div class="go-back" @click="goBack">
        <img src="~@/assets/arrow-dark.svg">
        <span>Gallery</span>
      </div>
      <img class="theme-captain" src="~@/assets/_economic/Theme-Business.svg">
      <div class="theme-words">
        <p>Singapore is home to American companies both new and old. Some of the longest-standing American enterprises are banks and companies dealing in shipping, rubber, and petroleum that have been here for more than a century. American film distributors were also a strong presence in the years immediately before and after World War II.
        </p><br>
        <p>The decades after the war saw a rise in American business and investment. Ford Motor Company was one of Singapore’s largest private employers, and in 1960, IBM installed Singapore’s first computer for the Central Provident Fund. Today, the United States is Singapore’s largest foreign investor. The Free Trade Agreement between the two countries supports 215,000 American jobs, and there are 4,200 American businesses in Singapore.</p>
      </div>
      <div class="scroll-hint">
        <img src="~@/assets/arrow-dark.svg">
        <p>Scroll to explore</p>
      </div>
    </div>
    <div class="theme-content">
      <div class="image-full">
        <img  src="~@/assets/_economic/1980-Apple-Factory.jpg">
      </div>
      <div class="right-text">
        <div class="right-text-block text-block">
          <p>Some U.S. technology companies that invested heavily in Singapore’s high-tech manufacturing sector in the 1980s were Hewlett-Packard, Micron, and Apple. The iconic Apple Macintosh personal computer was assembled at the company’s factory at Ang Mo Kio in the late 1980s (pictured). Today, there are 4,500 U.S. companies in Singapore, employing about 200,000 people.</p><br>
          <p>Image credit: Ministry of Information and the Arts Collection, courtesy of National Archives of Singapore</p><br><br>
          <p>Learn more about <a href="https://www.youtube.com/watch?v=uV6RZJjZOEc" target="_blank">Hewlett-Packard’s corporate culture</a>
            in Singapore</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-left-text">
        <div class="image-block">
          <img src="~@/assets/_economic/1923-Citibank-SG-Ocean-Building.jpg">
        </div>
        <div class="text-block">
          <p>Citi has operated in Singapore since 1902, when its predecessor company, the International Banking Corporation, became the first American bank to open here. It focused on financing tin and rubber exports from the Malay Peninsula. Pictured is its Ocean Building premises in 1923, which was demolished in 1970. Renamed Citibank in 1976, Citi Singapore is now one of the largest bank employers in Singapore.</p><br>
          <p>Image credit: Citi Singapore</p><br><br>
          <p>Learn more about <a href="https://www.youtube.com/watch?v=2JdsstYPJKQ
&list=PL64pfLZ8XYrG4kJVi8uiHShQaW8NOdHQ-" target="_blank">how American companies are making a difference</a> in Singapore</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-right-text">
        <div class="image-block">
          <img  src="~@/assets/_economic/1950-Ford-Factory.jpg">
        </div>
        <div class="text-block">
          <p>Ford Motor Company, the American automaker that modernized assembly-line manufacturing, began producing its signature Model T cars in Singapore in 1926. In 1941, it opened its first purpose-built motorcar assembly plant in Southeast Asia at Upper Bukit Timah Road (pictured here in the 1950s). Except for the interruption of World War II, Ford operated this plant until 1980, producing more than 150,000 vehicles.</p><br>
          <p>Courtesy of the National Museum of Singapore, National Heritage Board</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-full">
        <img  src="~@/assets/_economic/1973-Boeing-SIA.jpg">
      </div>
      <div class="right-text">
        <div class="right-text-block text-block">
          <p>In 1973, the nascent Singapore Airlines (SIA) received two B747s, its first wide-bodied aircraft, from Boeing, the American multinational aerospace company. Pictured are SIA flight attendants practicing in-flight service during a simulated flight in a real Boeing 747. Over the years, SIA has ordered more than 200 Boeing airplanes. Today, Boeing’s Southeast Asia headquarters is located in Singapore.</p><br>
          <p>Source: The Straits Times © SPH Media Limited. Permission required for reproduction.</p><br><br>
          <p>Watch a <a href="https://www.youtube.com/watch?v=Mg_wbrEDQys" target="_blank">TV advertisement for SIA’s first B747-200s</a> from
            the 1970s</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-left-text">
        <div class="image-block">
          <img src="~@/assets/_economic/2003-GohChokTongGWBush-FTA.jpg">
        </div>
        <div class="text-block">
          <p>On May 6, 2003, U.S. President George Bush (seated, right) and Singapore Prime Minister Goh Chok Tong (seated, left) signed the U.S.-Singapore Free Trade Agreement (FTA). Singapore was the first country in Asia with which the United States signed a FTA. It is the longest such agreement the latter has in Asia. The trade relationship between the two countries has doubled since the FTA came into force on January 1, 2004.</p><br>
          <p>Image credit: The White House, photo by Tina Hager</p><br><br>
          <p>Watch <a href="https://www.youtube.com/watch?v=prUPNTYaKeo" target="_blank">the historic signing of the FTA</a> in 2003</p><br><br>
          <p>Learn more about 55 years of bilateral relations between the United States and Singapore in this <a href="https://www.channelnewsasia.com/watch/us-singapore-relations-55/us-singapore-relations-55-2128461" target="_blank">documentary</a></p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-right-text">
        <div class="image-block">
          <img  src="~@/assets/_economic/2013-Google.jpg">
        </div>
        <div class="text-block">
          <p>In the internet age, American technology companies continue to have a strong presence in Singapore. For example, Singapore is currently Google’s Asia Pacific headquarters, and the company is building its third data center here, which will be one of the most efficient and environmentally friendly sites in Asia. The first, which opened in 2013, is pictured.</p><br>
          <p>Image credit: Google Singapore</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="share-block">
        <p>Like this photo collection? Share it with a friend!</p>
        <div class="share-icons">
          <ShareNetwork
              class="fb"
              network="facebook"
              url="https://ussg55inphotos.com/doing-business-together"
              title="From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos"
              hashtags="USSG55"
          >
            <img src="~@/assets/share-fb-dark.svg">
          </ShareNetwork>
          <ShareNetwork
              class="twitter"
              network="twitter"
              url="https://ussg55inphotos.com/doing-business-together"
              title='“From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos” – the virtual exhibition capturing highlights of the friendship, cooperation, and inspiration between the United States and Singapore through 55 photos.'
              hashtags="USSG55"
          ><img src="~@/assets/share-tw-dark.svg">
          </ShareNetwork>
          <a class="email" target="_blank" href='mailto:?subject=From Sea to Shining Sea: 55 Years of U.S.-Singapore Relations in Photos&body=Singapore is home to many American companies both new and old. Some of the longest-standing American enterprises are banks and companies dealing in shipping, rubber, and petroleum that have been here for more than a century. Today, the United States is Singapore&#39;s largest foreign investor.%0D%0ACheck out “From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos” – the virtual exhibition capturing highlights of the friendship, cooperation, and inspiration between the United States and Singapore through 55 photos. https://ussg55inphotos.com #USSG55'><img src="~@/assets/share-mail-dark.svg"></a>
          <div class="clear-float"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MusicalStars",
  methods: {
    goBack() {
      this.$router.push('explore');
    }
  }
}
</script>

<style scoped>

</style>