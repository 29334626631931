<template>
  <div class="theme bg-navy">
    <div class="theme-landing">
      <div class="go-back" @click="goBack">
        <img src="~@/assets/arrow-light.svg">
        <span>Gallery</span>
      </div>
      <img class="theme-captain" src="~@/assets/_diplomatic/Theme-Diplomatic-Connections.svg">
      <div class="theme-words">
        <p>The United States and Singapore have forged a close partnership on political, economic, security, and personal levels. In 2003, Singapore became the first country in Asia with which the United States signed a Free Trade Agreement. Since 1990, the two countries have had a Memorandum of Understanding facilitating U.S. forces’ access to Singapore’s military bases. This agreement was renewed in 2019 for another 15 years.
        </p><br>
        <p>The first official state visit between the two countries was in 1967, when U.S. President Lyndon B. Johnson received Singapore Prime Minister Lee Kuan Yew. More recently, Prime Minister Lee Hsien Loong made his first state visit to the United States in 2016, when he was honored by a White House state dinner, a first for Singapore. In 2021, Vice President Kamala Harris visited Singapore.</p>
      </div>
      <div class="scroll-hint">
        <img src="~@/assets/arrow-light.svg">
        <p>Scroll to explore</p>
      </div>
    </div>
    <div class="theme-content">
      <div class="image-full">
        <img  src="~@/assets/_diplomatic/2009-Obama-Clinton-Lee-Kuan-Yew-APEC-Event.jpg">
      </div>
      <div class="right-text">
        <div class="right-text-block text-block">
          <p>U.S. President Barack Obama (center) with Singapore’s Minister Mentor Lee Kuan Yew (right) and U.S. Secretary of State Hillary Rodham Clinton (seated) at a dinner for leaders attending the 2009 Asia-Pacific Economic Cooperation (APEC) meetings. The event was held in at APEC’s headquarters in Singapore.</p><br>
          <p>Image credit: Official White House Photo by Pete Souza</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-left-text">
        <div class="image-block">
          <img src="~@/assets/_diplomatic/1965-Edward-Kennedy.jpg">
        </div>
        <div class="text-block">
          <p>In 1965, U.S. Senator Edward Kennedy (center) spoke with student leaders from Singapore’s institutions of higher learning at the University of Singapore Students Union building. Kennedy was chairman of a Congressional subcommittee studying the refugee problem in Asia.</p><br>
          <p>Source: The Straits Times © SPH Media Limited. Permission required for reproduction.</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-right-text">
        <div class="image-block">
          <img src="~@/assets/_diplomatic/1985-Lee-Kuan-Yew-Ronald-Reagan.jpg">
        </div>
        <div class="text-block">
          <p>As Singapore’s Prime Minister, Lee Kuan Yew made three state visits to the White House in 1973, 1975, and 1985. Each time, he was honored with a state dinner. Pictured here is Prime Minister Lee (left) with President Ronald Reagan (right) in the Diplomatic Reception Room at the White House during the 1985 visit.</p><br>
          <p>Image credit: Ronald Reagan Presidential Library and Museum/National Archives and Records Administration</p><br><br>
          <p>Watch the <a href="https://www.youtube.com/watch?v=uZFYpQUAoSk" target="_blank">arrival ceremony for Prime Minister Lee</a> at the start of his official visit to the White House in 1985</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-full">
        <img  src="~@/assets/_diplomatic/1997-Madeline-Albright.jpg">
      </div>
      <div class="right-text">
        <div class="right-text-block text-block">
          <p>The first female U.S. Secretary of State, Madeline Albright (in wide-brimmed hat), arrived in Singapore on a stopover in 1997. She met then Senior Minister Lee Kuan Yew, as well as visited the Revere Bell at the National Museum. She returned in 1999 to attend the ASEAN Regional Forum and ASEAN Post-Ministerial Conference.</p><br>
          <p>Image credit: U.S. Embassy Singapore</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-full">
        <img  src="~@/assets/_diplomatic/2006-George-W-Bush-Wife-Gamelan-Orchestra.jpg">
      </div>
      <div class="left-text">
        <div class="left-text-block text-block">
          <p>U.S. President George W. Bush (in suit) and First Lady Laura Bush (extreme right) tried their hand at gamelan instruments at the Asian Civilisations Museum. They were visiting Singapore en route to the 2006 APEC Summit in Vietnam. This was President Bush’s second visit to Singapore.</p><br>
          <p>Image credit: Official White House photo by Paul Morse</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-right-text">
        <div class="image-block">
          <img src="~@/assets/_diplomatic/2016-White-House-State-Dinner.jpg">
        </div>
        <div class="text-block">
          <p>U.S. President Barack Obama (extreme right) and First Lady Michelle Obama (in white) entering the state dinner held on August 2, 2016 for Singapore Prime Minister Lee Hsien Loong (second from right) and his wife Ho Ching (in red). This was the first state dinner hosted by the White House for Singapore and marked fifty years of diplomacy between the two countries.</p><br>
          <p>Image credit: Ministry of Communications and Information Collection, courtesy of National Archives of Singapore</p><br><br>
          <p>Watch the <a href="https://www.youtube.com/watch?v=FyOtXHQwj60" target="_blank">arrival ceremony for Prime Minister Lee</a> at the start of his official visit to the White House in 2016</p><br><br>
          <p>Check out <a href="https://obamawhitehouse.archives.gov/sites/default/files/
image/files/singaporestatedinner.pdf" target="_blank">the menu and other details</a> for the state dinner</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-full">
        <img  src="~@/assets/_diplomatic/2021-VP-Harris-Istana.jpg">
      </div>
      <div class="left-text">
        <div class="left-text-block text-block">
          <p>U.S. Vice President Kamala Harris made her first official visit to Singapore on August 22–23, 2021. She met Singapore President Halimah Yacob, and, with Prime Minister Lee Hsien Loong, announced new partnerships to address the climate crisis, cybersecurity, and growth and innovation. At the Istana, Vice President Harris also received a hybrid orchid named after her, <span class="italic">Papilionanda Kamala Harris</span> (pictured).</p><br>
          <p>Image credit: Official White House photo by Lawrence Jackson</p><br><br>
          <p>Catch up on <a href="https://sg.usembassy.gov/vp-harris-trip-singapore-vietnam/" target="_blank">the highlights of Vice President Harris’s visit</a>
            to Singapore</p><br><br>
          <p>Learn more about 55 years of bilateral relations between the United States and Singapore in this <a href="https://www.channelnewsasia.com/watch/us-singapore-relations-55/us-singapore-relations-55-2128461" target="_blank">documentary</a></p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="share-block">
        <p>Like this photo collection? Share it with a friend!</p>
        <div class="share-icons">
          <ShareNetwork
              class="fb"
              network="facebook"
              url="https://ussg55inphotos.com/diplomatic-connections"
              title="From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos"
              hashtags="USSG55"
          >
            <img src="~@/assets/share-fb-light.svg">
          </ShareNetwork>
          <ShareNetwork
              class="twitter"
              network="twitter"
              url="https://ussg55inphotos.com/diplomatic-connections"
              title='“From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos” – the virtual exhibition capturing highlights of the friendship, cooperation, and inspiration between the United States and Singapore through 55 photos.'
              hashtags="USSG55"
          ><img src="~@/assets/share-tw-light.svg">
          </ShareNetwork>
          <a class="email" target="_blank" href='mailto:?subject=From Sea to Shining Sea: 55 Years of U.S.-Singapore Relations in Photos&body=The United States and Singapore have forged a close partnership on a range of interests covering political, economic, security, and people-to-people links. In 2003, Singapore became the first country in Asia to sign a Free Trade Agreement with the United States.%0D%0ACheck out “From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos” – the virtual exhibition capturing highlights of the friendship, cooperation, and inspiration between the United States and Singapore through 55 photos. https://ussg55inphotos.com #USSG55'><img src="~@/assets/share-mail-light.svg"></a>
          <div class="clear-float"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DiplomaticConnections",
  methods: {
    goBack() {
      this.$router.push('explore');
    }
  }
}
</script>