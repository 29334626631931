<template>
  <div class="home bg-blue">
    <div class="wave-top">
      <img src="~@/assets/wave-desktop-1.svg">
    </div>
    <div class="wave-bottom">
      <img src="~@/assets/wave-desktop-2.svg">
    </div>
    <div class="content" v-if="step == 1">
      <div class="slider" v-if="!isMobile">
        <img :class="{ 'slider-active': sliderShow == 1 }" src="~@/assets/landing/desktop-photo-1.jpg">
        <img :class="{ 'slider-active': sliderShow == 2 }" src="~@/assets/landing/desktop-photo-2.jpg">
        <img :class="{ 'slider-active': sliderShow == 3 }" src="~@/assets/landing/desktop-photo-3.jpg">
        <img :class="{ 'slider-active': sliderShow == 4 }" src="~@/assets/landing/desktop-photo-4.jpg">
        <img :class="{ 'slider-active': sliderShow == 5 }" src="~@/assets/landing/desktop-photo-5.jpg">
        <img :class="{ 'slider-active': sliderShow == 6 }" src="~@/assets/landing/desktop-photo-6.jpg">
      </div>
      <div class="slider" v-if="isMobile">
        <img :class="{ 'slider-active': sliderShow == 1 }" src="~@/assets/landing/mobile-photo-1.jpg">
        <img :class="{ 'slider-active': sliderShow == 2 }" src="~@/assets/landing/mobile-photo-2.jpg">
        <img :class="{ 'slider-active': sliderShow == 3 }" src="~@/assets/landing/mobile-photo-3.jpg">
        <img :class="{ 'slider-active': sliderShow == 4 }" src="~@/assets/landing/mobile-photo-4.jpg">
        <img :class="{ 'slider-active': sliderShow == 5 }" src="~@/assets/landing/mobile-photo-5.jpg">
        <img :class="{ 'slider-active': sliderShow == 6 }" src="~@/assets/landing/mobile-photo-6.jpg">
      </div>
      <img class="master-head-top" src="~@/assets/masthead-top.svg">
      <img class="master-head-bottom" src="~@/assets/masthead-bottom.svg">
      <p class="text-welcome" style="visibility: hidden;position: absolute;">&nbsp;</p>
    </div>
    <div class="content-click" v-if="step == 1">
      <div class="home-next button-next color-navy" @click="nextStep">
        <img src="~@/assets/arrow-blue.svg">
      </div>
    </div>
    <div class="content-music" v-if="step == 2">
      <p class="text-music-instruction">For the best experience, use<br>headphones and turn sound on<br></p>
      <div class="music-control-on color-navy" @click="playMusic" v-if="musicOff">
<!--        <p>-->
          <span>Unmute</span><img src="~@/assets/sound-off-blue.svg">
<!--        </p>-->
      </div>
      <div class="music-control-off color-navy" @click="stopMusic" v-if="!musicOff">
        <p><img src="~@/assets/sound-on-blue.svg"></p>
      </div>
      <div class="clear-float"></div>
      <div class="text-welcome desktop">
        Welcome to a virtual exhibition capturing highlights<br>
        of the friendship, cooperation, and inspiration<br>
        between the United States and Singapore.
      </div>
      <div class="text-welcome mobile">
        Welcome to a virtual exhibition capturing highlights of the friendship, cooperation, and inspiration between the United States and Singapore.
      </div>
      <div class="page-next button-next color-navy" @click="nextPage">
        <img src="~@/assets/arrow-blue.svg">
      </div>
    </div>
    <div class="logo">
      <img src="~@/assets/logos.png" alt="logo">
    </div>
    <div class="hashtag">
      <img src="~@/assets/ussg55.svg" alt="hashtag">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      sliderShow: 1,
      timer: null,
      step: 1,
      musicOff: true,
      isMobile: false,
    }
  },
  methods: {
    nextStep() {
      this.step = 2;
    },
    nextPage() {
      this.$router.push('explore');
    },
    playMusic() {
      this.$emit('playMusic');
      this.musicOff = false;
      window.musicOff = false;
    },
    stopMusic() {
      this.$emit('stopMusic');
      this.musicOff = true;
      window.musicOff = true;
    }
  },
  mounted() {
    var self = this;
    this.isMobile = window.isMobile;
    this.timer = setInterval(function() {
      self.sliderShow++;
      self.sliderShow = self.sliderShow % 6;
      if (self.sliderShow == 0) {
        self.sliderShow = 6;
      }
    }, 3000);
  },
  unmounted() {
    clearInterval(this.timer);
  }
}
</script>
