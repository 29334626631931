import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Explore from '../views/Explore.vue';
import MusicalStars from '../views/MusicalStars.vue';
import Singapore from '../views/Singapore.vue';
import Security from '../views/Security.vue';
import DiplomaticConnections from '../views/DiplomaticConnections.vue';
import Business from '../views/Business.vue';
import CultureInAction from '../views/CultureInAction.vue';
import LearnTogether from '../views/LearnTogether.vue';
import Library from "../views/Library.vue";
import FurtherResearch from "../views/FurtherResearch.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: About
  // },
  {
    path: '/explore',
    name: 'Explore',
    component: Explore
  },
  {
    path: '/musical-stars',
    name: 'MusicalStars',
    component: MusicalStars
  },
  {
    path: '/singapore-through-an-american-lens',
    name: 'Singapore',
    component: Singapore
  },
  {
    path: '/strength-in-security-cooperation',
    name: 'Security',
    component: Security
  },
  {
    path: '/diplomatic-connections',
    name: 'DiplomaticConnections',
    component: DiplomaticConnections
  },
  {
      path: '/doing-business-together',
    name: 'Business',
    component: Business
  },
  {
    path: '/culture-in-action',
    name: 'CultureInAction',
    component: CultureInAction
  },
  {
    path: '/learn-together',
    name: 'LearnTogether',
    component: LearnTogether
  },
  {
    path: '/library-picks-just-for-you',
    name: 'Library',
    component: Library
  },
  {
    path: '/further-research',
    name: 'FurtherResearch',
    component: FurtherResearch
  },
  {
    // will match everything
    path: '/:pathMatch(.*)*',
    component: Home
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
