<template>
  <div class="explore">
    <div class="grid" id="drag">
      <div class="row row-1">
        <div class="column"></div>
        <div class="column"></div>
        <div class="column"></div>
        <div class="column"></div>
        <div class="column"></div>
        <div class="column">
          <div class="story-grid bottom-right" @click="popup('r1c6')">
            <img src="~@/assets/_popups/2006-George-W-Bush-Wife-Gamelan-Orchestra_thumb.jpg">
          </div>
        </div>
        <div class="column"></div>
        <div class="column"></div>
        <div class="column"></div>
        <div class="column"></div>
        <div class="column"></div>
        <div class="clear-float"></div>
      </div>
      <div class="row row-2">
        <div class="column"></div>
        <div class="column">
          <div class="story-grid bottom-left" @click="popup('r2c2')">
            <img src="~@/assets/_popups/1951-USIS-Raffles-Place_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid" @click="popup('r2c3')">
            <img src="~@/assets/_popups/1980-Paul-Rudolph-Concourse_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="theme-grid bg-blue bottom-left" @click="goto('/musical-stars')">
            <p>
              Musical<br>
              Stars
            </p>
          </div>
        </div>
        <div class="column"></div>
        <div class="column">
          <div class="story-grid bottom-left" @click="popup('r2c6')">
            <img src="~@/assets/_popups/1964-Louis-Armstrong_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid" @click="popup('r2c7')">
            <img src="~@/assets/_popups/1966-US-Embassy_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="theme-grid bg-navy bottom-left" @click="goto('/strength-in-security-cooperation')">
            <p>
              Strength In<br>
              Security<br>
              Cooperation
            </p>
          </div>
        </div>
        <div class="column">
          <div class="story-grid" @click="popup('r2c9')">
            <img src="~@/assets/_popups/Jordi-Fornie-People-of-Singapore_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-left" @click="popup('r2c10')">
            <img src="~@/assets/_popups/George-Porter-Family-in-MG-1967_thumb.jpg">
          </div>
        </div>
        <div class="column"></div>
        <div class="clear-float"></div>
      </div>
      <div class="row row-3">
        <div class="column"></div>
        <div class="column"></div>
        <div class="column">
          <div class="story-grid top-right" @click="popup('r3c3')">
            <img src="~@/assets/_popups/1950-Ford-Factory_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-right" @click="popup('r3c4')">
            <img src="~@/assets/_popups/2014-CARAT_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="theme-grid bg-red top-right"  @click="goto('/learn-together')">
            <p>
              Let's Learn<br>
              Together
            </p>
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-right" @click="popup('r3c6')">
            <img src="~@/assets/_popups/Tan-Luo-Yi-Hoard_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid top-right" @click="popup('r3c7')">
            <img src="~@/assets/_popups/Young-Scholars-February-1970_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-right" @click="popup('r3c8')">
            <img src="~@/assets/_popups/1974-Buckminister-Fuller_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid top-right" @click="popup('r3c9')">
            <img src="~@/assets/_popups/2021-VP-Harris-Istana_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-right" @click="popup('r3c10')">
            <img src="~@/assets/_popups/1955-60-Athletics_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid top-right" @click="popup('r3c11')">
            <img src="~@/assets/_popups/1965-Edward-Kennedy_thumb.jpg">
          </div>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="row row-4">
        <div class="column"></div>
        <div class="column">
          <div class="theme-grid bg-navy bottom-left" @click="goto('/further-research')">
            <p>
              Further<br>
              Research
            </p>
          </div>
        </div>
        <div class="column">
          <div class="story-grid" @click="popup('r4c3')">
            <img src="~@/assets/_popups/June-Chua-Stonewall-Inn-IVLP_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-left" @click="popup('r4c4')">
            <img src="~@/assets/_popups/Daydreaming-Shop-Girl-October-1969_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid" @click="popup('r4c5')">
            <img src="~@/assets/_popups/1977-Mickey-Minnie-Mouse_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-left" @click="popup('r4c6')">
            <img src="~@/assets/_popups/Margaret-Leng-Tan_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="theme-grid bg-navy" @click="goto('/diplomatic-connections')">
            <p>
              Diplomatic<br>
              Connections
            </p>
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-left" @click="popup('r4c8')">
            <img src="~@/assets/_popups/The-Tiller-and-the-Thinker-1960s_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid" @click="popup('r4c9')">
            <img src="~@/assets/_popups/Hoe-Juan-Sim-SAS_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-left" @click="popup('r4c10')">
            <img src="~@/assets/_popups/2012-Naval-Academy-Sam-Tan_thumb.jpg">
          </div>
        </div>
        <div class="column"></div>
        <div class="clear-float"></div>
      </div>
      <div class="row row-5">
        <div class="column">
          <div class="story-grid top-right" @click="popup('r5c1')">
            <img src="~@/assets/_popups/1955-American-Club_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-right" @click="popup('r5c2')">
            <img src="~@/assets/_popups/1990-IM-Pei-Gateway_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid top-right" @click="popup('r5c3')">
            <img src="~@/assets/_popups/1965-Man-In-Space_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-right" @click="popup('r5c4')">
            <img src="~@/assets/_popups/Mohd-Yafaridah-Spring-Grove_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid top-right" @click="popup('r5c5')">
            <img src="~@/assets/_popups/2003-GohChokTongGWBush-FTA_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-right" @click="popup('r5c6')">
            <img src="~@/assets/_popups/1960-San-Francisco-Ballet_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid top-right" @click="popup('r5c7')">
            <img src="~@/assets/_popups/1973-Boeing-SIA_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-right" @click="popup('r5c8')">
            <img src="~@/assets/_popups/2017-George-Washington-Ball_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="theme-grid bg-blue top-right" @click="goto('/library-picks-just-for-you')">
            <p>
              Library Picks<br>
              Just For You
            </p>
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-right" @click="popup('r5c10')">
            <img src="~@/assets/_popups/2016-White-House-State-Dinner_thumb.jpg">
          </div>
        </div>
        <div class="column"></div>
        <div class="clear-float"></div>
      </div>
      <div class="row row-6">
        <div class="column"></div>
        <div class="column">
          <div class="story-grid bottom-left" @click="popup('r6c2')">
            <img src="~@/assets/_popups/1973-Harlem-Globetrotters_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid" @click="popup('r6c3')">
            <img src="~@/assets/_popups/1997-Madeline-Albright_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="theme-grid bg-red bottom-left" @click="goto('/culture-in-action')">
            <p>
              Culture<br>
              In Action
            </p>
          </div>
        </div>
        <div class="column">
          <div class="story-grid" @click="popup('r6c5')">
            <img src="~@/assets/_popups/1956-Singapore-American-School_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-left" @click="popup('r6c6')">
            <img src="~@/assets/_popups/Money-Changer-November-1969_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid" @click="popup('r6c7')">
            <img src="~@/assets/_popups/2009-Obama-Clinton-Lee-Kuan-Yew-APEC-Event_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-left" @click="popup('r6c8')">
            <img src="~@/assets/_popups/1983-Revere-Bell_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid" @click="popup('r6c9')">
            <img src="~@/assets/_popups/Victor-Savage-Fulbright_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-left" @click="popup('r6c10')">
            <img src="~@/assets/_popups/1972-Duke-Ellington_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid top-right" @click="popup('r6c11')">
            <img src="~@/assets/_popups/2010-Valiant-Mark_thumb.jpg">
          </div>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="row row-7">
        <div class="column"></div>
        <div class="column"></div>
        <div class="column">
          <div class="story-grid top-right" @click="popup('r7c3')">
            <img src="~@/assets/_popups/1973-Muhammad-Ali_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-right" @click="popup('r7c4')">
            <img src="~@/assets/_popups/The-Yellow-Bird-1965-1970_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid top-right" @click="popup('r7c5')">
            <img src="~@/assets/_popups/1969-Eartha-Kitt_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="theme-grid bg-blue bottom-right" @click="goto('/singapore-through-an-american-lens')">
            <p>
              1960S<br>
              Singapore<br>
              Through An<br>
              American<br>
              Lens
            </p>
          </div>
        </div>
        <div class="column">
          <div class="story-grid top-right" @click="popup('r7c7')">
            <img src="~@/assets/_popups/2003-Lee-Wen-Strange-Fruit_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-right" @click="popup('r7c8')">
            <img src="~@/assets/_popups/Hand-in-Hand-1968_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="theme-grid bg-red top-right" @click="goto('/doing-business-together')">
            <p>
              Doing<br>
              Business<br>
              Together
            </p>
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-right" @click="popup('r7c10')">
            <img src="~@/assets/_popups/2013-Google_thumb.jpg">
          </div>
        </div>
        <div class="column"></div>
        <div class="clear-float"></div>
      </div>
      <div class="row row-8">
        <div class="column"></div>
        <div class="column">
          <div class="story-grid bottom-left" @click="popup('r8c2')">
            <img src="~@/assets/_popups/Sonny-Liew_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid" @click="popup('r8c3')">
            <img src="~@/assets/_popups/1923-Citibank-SG-Ocean-Building_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-left" @click="popup('r8c4')">
            <img src="~@/assets/_popups/1950s-USIS-Library_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid" @click="popup('r8c5')">
            <img src="~@/assets/_popups/1980-Apple-Factory_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-left" @click="popup('r8c6')">
            <img src="~@/assets/_popups/Christopherson-Ho-Memories-of-Lunar_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid" @click="popup('r8c7')">
            <img src="~@/assets/_popups/2019-Valiant-Mark_thumb.jpg">
          </div>
        </div>
        <div class="column">
        </div>
        <div class="column">
          <div class="story-grid" @click="popup('r8c9')">
            <img src="~@/assets/_popups/2021-RSAF-Nellis-Air-Force-Base_thumb.jpg">
          </div>
        </div>
        <div class="column">
          <div class="story-grid bottom-left" @click="popup('r8c10')">
            <img src="~@/assets/_popups/1985-Lee-Kuan-Yew-Ronald-Reagan_thumb.jpg">
          </div>
        </div>
        <div class="column"></div>
        <div class="clear-float"></div>
      </div>
      <div class="row row-9">
        <div class="column"></div>
        <div class="column"></div>
        <div class="column"></div>
        <div class="column"></div>
        <div class="column">
          <div class="story-grid top-right" @click="popup('r9c6')">
            <img src="~@/assets/_popups/1962-Zubir-Said-New-York-Woodwind-Quintet_thumb.jpg">
          </div>
        </div>
        <div class="column">
        </div>
        <div class="column"></div>
        <div class="column"></div>
        <div class="column"></div>
        <div class="column"></div>
        <div class="column"></div>
        <div class="clear-float"></div>
      </div>
    </div>
    <div class="about-button" @click="showAbout()">
      <p>About</p>
    </div>
    <div class="share-button" @click="displayShareButtons()">
      <img src="~@/assets/share.svg">
    </div>
    <div class="share-icon-block" v-if="displayShare">
      <ShareNetwork
          class="fb"
          network="facebook"
          url="https://ussg55inphotos.com"
          title="From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos"
          description='Check out “From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos” – the virtual exhibition capturing highlights of the friendship, cooperation, and inspiration between the United States and Singapore through 55 photos. https://ussg55inphotos.com #USSG55'
          hashtags="USSG55"
      >
        <img src="~@/assets/share-fb-dark.svg">
      </ShareNetwork>
      <ShareNetwork
          class="twitter"
          network="twitter"
          url="https://ussg55inphotos.com"
          title='“From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos” – the virtual exhibition capturing highlights of the friendship, cooperation, and inspiration between the United States and Singapore through 55 photos.'
          hashtags="USSG55"
      ><img src="~@/assets/share-tw-dark.svg">
      </ShareNetwork>
      <a class="email" target="_blank" href='mailto:?subject=From Sea to Shining Sea: 55 Years of U.S.-Singapore Relations in Photos&body=Americans and Singaporeans have played and competed in sports, enjoyed and created music and art together, and inspired each other&#39;s views of the world.%0D%0ACheck out “From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos” – the virtual exhibition capturing highlights of the friendship, cooperation, and inspiration between the United States and Singapore through 55 photos. https://ussg55inphotos.com #USSG55'><img src="~@/assets/share-mail-dark.svg"></a>
    </div>
    <div class="music-button" v-if="this.musicOff" @click="playMusic()">
      <img src="~@/assets/sound-off-dark.svg">
    </div>
    <div class="music-button" v-if="!this.musicOff" @click="stopMusic()">
      <img src="~@/assets/sound-on-dark.svg">
    </div>
    <div :class="{ popup: true, 'popup-active': popupActive, 'popup-closing': popupClosing }">
      <div :class="{ 'popup-content': true, 'popup-v': popupV }">
        <img v-if="popupCounter === 1" :class="{'popup-img': true, 'popup-show': imgLoad1}" :src="popupImg" @load="onImgLoad">
        <img v-if="popupCounter === 2" :class="{'popup-img': true, 'popup-show': imgLoad2}" :src="popupImg2" @load="onImgLoad2">
        <div class="popup-caption" v-html="popupCaption">
        </div>
      </div>
      <div class="popup-close" @click="closePopup">
        <img src="~@/assets/close.svg">
      </div>
    </div>
    <div class="hint" v-if="!hintClicked">
      <div class="hint-block">
        <div class="hint-buttons">
          <div class="hint-button">
            <img src="~@/assets/move.svg">
            <p>Drag to move</p>
          </div>
          <div class="hint-button">
            <img src="~@/assets/click.svg">
            <p>Click or tap<br>for more</p>
          </div>
          <div class="clear-float"></div>
        </div>
        <button class="explore-button" @click="closeHint">Explore</button>
      </div>
    </div>
    <about :class="{'about-active': aboutActive, 'about-closing': aboutClosing}"
           v-on:close="closeAbout()"
    ></about>
  </div>
</template>

<script>
import { popup } from '@/popup';
import About from '../views/About.vue';

export default {
  name: "Explore",
  components: {About},
  emits: ['playMusic', 'stopMusic'],
  mounted() {
    this.dragElement(document.getElementById('drag'));
    this.musicOff = window.musicOff;
  },
  data() {
    return {
      imgLoad1: false,
      imgLoad2: false,
      popupCounter: 1,
      popupV: false,
      popupImg: '',
      popupImg2: '',
      popupCaption: '',
      popupActive: false,
      popupClosing: false,
      displayShare: false,
      musicOff: null,
      hintClicked: false,
      aboutActive: false,
      aboutClosing: false,
    }
  },
  methods: {
    closeHint() {
      this.hintClicked = true;
    },
    displayShareButtons() {
      this.displayShare = !this.displayShare;
    },
    playMusic() {
      this.$emit('playMusic');
      window.musicOff = false;
      this.musicOff = false;
    },
    stopMusic() {
      this.$emit('stopMusic');
      window.musicOff = true;
      this.musicOff = true;
    },
    showAbout() {
      this.aboutActive = true;
    },
    closeAbout() {
      this.aboutActive = false;
      this.aboutClosing = true;
      setTimeout(() => {
        this.aboutClosing = false;
      }, 1000);
    },
    onImgLoad() {
      this.imgLoad1 = true;
      console.log(this.imgLoad1);
    },
    onImgLoad2() {
      this.imgLoad2 = true;
      console.log(this.imgLoad2);
    },
    popup(name) {
      this.imgLoad1 = false;
      this.imgLoad2 = false;

      if (this.popupCounter === 1) {
        this.popupCounter = 2;
        this.popupImg2 = '/img/_popups/' + popup[name].popupImg;
      } else {
        this.popupCounter = 1;
        this.popupImg = '/img/_popups/' + popup[name].popupImg;
      }
      this.popupV = popup[name].popupV;
      this.popupCaption = popup[name].popupCaption;
      this.popupActive = true;
    },
    closePopup() {
      this.popupActive = false;
      this.popupClosing = true;
      setTimeout(() => {
        this.popupClosing = false;
      }, 500);
    },
    dragElement(domEl) {
      let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

      domEl.onmousedown = dragMouseDown;
      domEl.ontouchstart = dragMouseDown;

      function dragMouseDown(e) {
        e = e || window.event;

        // get the mouse cursor position at startup:
        if (e.targetTouches) {
          pos3 = e.targetTouches[0].clientX;
          pos4 = e.targetTouches[0].clientY;
        } else {
          pos3 = e.clientX;
          pos4 = e.clientY;
        }

        document.onmouseup = closeDragElement;
        document.ontouchend = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
        document.ontouchmove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:

        if (e.targetTouches) {
          pos1 = pos3 - e.targetTouches[0].clientX;
          pos2 = pos4 - e.targetTouches[0].clientY;
          pos3 = e.targetTouches[0].clientX;
          pos4 = e.targetTouches[0].clientY;
        } else {
          pos1 = pos3 - e.clientX;
          pos2 = pos4 - e.clientY;
          pos3 = e.clientX;
          pos4 = e.clientY;
        }

        // set the element's new position:
        let newTop = domEl.offsetTop - pos2;
        let newLeft = domEl.offsetLeft - pos1;

        if (window.innerWidth < 1024) {
          if (newLeft > 0) {
            newLeft = 0
          } else if (newLeft < window.innerWidth - 2200) {
            newLeft = window.innerWidth - 2200
          }

          if (newTop > 0) {
            newTop = 0
          } else if (newTop < window.innerHeight - 1792) {
            newTop = window.innerHeight - 1792
          }
        } else {
          if (newLeft > 0) {
            newLeft = 0
          } else if (newLeft < window.innerWidth - 3580) {
            newLeft = window.innerWidth - 3580
          }

          if (newTop > 0) {
            newTop = 0
          } else if (newTop < window.innerHeight - 2916) {
            newTop = window.innerHeight - 2916
          }
        }

        domEl.style.top = newTop + "px";
        domEl.style.left = newLeft + "px";
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
        document.ontouchend = null;
        document.ontouchmove = null;
      }
    },
    goto(url) {
      this.$router.push(url);
    }
  }
}
</script>

<style scoped>

</style>