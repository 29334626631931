<template>
  <router-view v-slot="{ Component }" v-on:play-music="playMusic" v-on:stop-music="stopMusic">
    <transition name="fade" appear>
      <keep-alive>
        <component :is="Component"/>
      </keep-alive>
    </transition>
  </router-view>
  <div class="hidden">
    <audio id="background-music" preload loop src="/sounds/USSG55.mp3"></audio>
  </div>
</template>

<script>
export default {
  methods: {
    playMusic() {
      document.querySelector("#background-music").play();
    },
    stopMusic() {
      document.querySelector("#background-music").pause();
    }
  },
}
</script>