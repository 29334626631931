import './assets/css/common.css';
import './assets/css/desktop.css';
import './assets/css/laptop.css';
import './assets/css/mobile.css';
import './assets/css/iphonese.css';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueSocialSharing from 'vue-social-sharing';

window.musicOff = true;

window.isMobile = window.innerWidth < 1024;

createApp(App).use(router).use(VueSocialSharing)
    .mount('#app');

document.addEventListener('contextmenu', event => event.preventDefault());