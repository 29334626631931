<template>
  <div class="theme bg-main">
    <div class="theme-landing">
      <div class="go-back" @click="goBack">
        <img src="~@/assets/arrow-dark.svg">
        <span>Gallery</span>
      </div>
      <img class="theme-captain" src="~@/assets/_porter/Theme-1960s-Singapore.svg">
      <div class="theme-words">
        <p>George W. Porter was an American diplomat at the U.S. Embassy Singapore from 1965 to 1970. During his lunch hours and leisure time, he took photographs of everyday life, particularly the people and activities at the Singapore River, which was a short walk from the Embassy building at Hill Street.
        </p><br>
        <p>Before Porter’s death in 2015, he donated a number of prints to the National Archives of Singapore. They are an important record of the bygone life that once flourished in what is now Singapore’s Civic District. In 2015, the U.S. Embassy Singapore invited students at LASALLE College of the Arts in Singapore to propose responses to Porter’s images. The artworks were exhibited at the college from October 15 to November 5, 2015; a selection of three works is included here.</p>
      </div>
      <div class="scroll-hint">
        <img src="~@/assets/arrow-dark.svg">
        <p>Scroll to explore</p>
      </div>
    </div>
    <div class="theme-content">
      <div class="image-full">
        <img  src="~@/assets/_porter/Hand-in-Hand-1968.jpg">
      </div>
      <div class="left-text">
        <div class="left-text-block text-block">
          <p>“Hand-in-hand” (1968)</p>
          <p>This photograph was taken by George Porter, an American diplomat at the U.S. Embassy Singapore from 1965 to 1970. The children are walking by a sundry store, known colloquially as a mamak shop (mamak means “uncle” in Tamil). These shops used to be a common sight in Singapore but are rare in the downtown area today.</p><br>
          <p>Image credit: George W. Porter Collection, courtesy of National Archives of Singapore</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-right-text">
        <div class="image-block">
          <img src="~@/assets/_porter/George-Porter-Family-in-MG-1967.jpg">
        </div>
        <div class="text-block">
          <p>George Porter (at the wheel) was an American diplomat at the U.S. Embassy Singapore from 1965 to 1970. During his lunch hours and leisure time, he took numerous photographs of everyday life. This family photo in their MG (a British car) was most likely taken on the grounds of Spring Grove, the U.S. Ambassador’s residence.</p><br>
          <p>Image credit: The family of George Porter</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-left-text">
        <div class="image-block">
          <img src="~@/assets/_porter/Money-Changer-November-1969.jpg">
        </div>
        <div class="text-block">
          <p>“Money changer” (November 1969)</p>
          <p>This photograph was taken by George Porter, an American diplomat at the U.S.Embassy Singapore from 1965 to 1970. The sundry store pictured also offers money changing services. The shopkeeper is wearing a sarong, which used to be more common attire among South Indian men.</p><br>
          <p>Image credit: George W. Porter Collection, courtesy of National Archives of Singapore</p><br><br>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-right-text">
        <div class="image-block">
          <img src="~@/assets/_porter/The-Tiller-and-the-Thinker-1960s.jpg">
        </div>
        <div class="text-block">
          <p>“The tiller and the thinker” (1960s)</p>
          <p>This photograph was taken by George Porter, an American diplomat at the U.S. Embassy Singapore from 1965 to 1970. It was taken at the Singapore River. The boats, known in Hokkien and Teochew as twakow, and in Malay as tongkang, were used to transport cargo from ships in the harbor to warehouses or vehicles along the river.</p><br>
          <p>Image credit: George W. Porter Collection, courtesy of National Archives of Singapore</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-full">
        <img  src="~@/assets/_porter/Young-Scholars-February-1970.jpg">
      </div>
      <div class="left-text">
        <div class="left-text-block text-block">
          <p>“Young scholars” (February 1970)</p>
          <p>This photograph of primary school students was taken by George Porter, an American diplomat at the U.S. Embassy Singapore from 1965 to 1970. The location and identity of the students is unknown.</p><br>
          <p>Image credit: George W. Porter Collection, courtesy of National Archives of Singapore</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-left-text">
        <div class="image-block">
          <img src="~@/assets/_porter/Jordi-Fornie-People-of-Singapore.jpg">
        </div>
        <div class="text-block">
          <p>Jordi Fornies, <span class="italic">People of Singapore</span> (2015)</p>
          <p>In 2015, students at LASALLE College of the Arts exhibited new artworks responding to George Porter’s photographs. Fornies’s work was inspired by Porter’s “Young scholars.” It features Polaroid images of people in Singapore on a blackboard and projected on a door. It is accompanied by a soundtrack created by Fornies, using adjectives people used to describe Singapore.</p><br>
          <p>Image credit: LASALLE College of the Arts</p><br><br>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-right-text">
        <div class="image-block">
          <img src="~@/assets/_porter/The-Yellow-Bird-1965-1970.jpg">
        </div>
        <div class="text-block">
          <p>“The yellow bird” (1965–1970)</p>
          <p>This photograph was taken by George Porter, an American diplomat at the U.S. Embassy Singapore from 1965 to 1970. The shop is selling colorful animal-shaped lanterns made of wire and cellophane. These were popular with children during the annual Mid-Autumn Festival (also known as the Mooncake Festival).</p><br>
          <p>Image credit: George W. Porter Collection, courtesy of National Archives of Singapore</p><br><br>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-full">
        <img  src="~@/assets/_porter/Christopherson-Ho-Memories-of-Lunar.jpg">
      </div>
      <div class="left-text">
        <div class="left-text-block text-block">
          <p>Christopherson Ho, <span class="italic">Memories of lunar</span> (2015)</p>
          <p>In 2015, students at LASALLE College of the Arts exhibited new artworks responding to George Porter’s photographs. Ho responded to Porter’s “The yellow bird” and was further inspired by Ho’s own memories of celebrating the Mid-Autumn Festival. The installation consists of 20 ceramic pieces made in the shape of lanterns, suspended from bamboo poles.</p><br>
          <p>Image credit: LASALLE College of the Arts</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-full">
        <img  src="~@/assets/_porter/Daydreaming-Shop-Girl-October-1969.jpg">
      </div>
      <div class="left-text">
        <div class="left-text-block text-block">
          <p>“Daydreaming girl in a shop” (October 1969)</p>
          <p>This photograph was taken by George Porter, an American diplomat at the U.S. Embassy Singapore from 1965 to 1970. The location and identity of the woman is unknown. The photograph might have been taken near the U.S. Embassy Singapore in Hill Street, which was close to the High Street shopping district that was popular at the time.</p><br>
          <p>Image credit: George W. Porter Collection, courtesy of National Archives of Singapore</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-right-text">
        <div class="image-block">
          <img src="~@/assets/_porter/Tan-Luo-Yi-Hoard.jpg">
        </div>
        <div class="text-block">
          <p>Tan Luo Yi, <span class="italic">Hoard</span> (2015)</p>
          <p>In 2015, students at LASALLE College of the Arts exhibited new artworks responding to George Porter’s photographs. Tan was inspired by Porter’s “The yellow bird” and “Daydreaming shop girl.” The work is an installation of found objects. It highlights the visually cluttered shops from the past, as captured in the photos.</p><br>
          <p>Image credit: LASALLE College of the Arts</p><br><br>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="share-block">
        <p>Like this photo collection? Share it with a friend!</p>
        <div class="share-icons">
          <ShareNetwork
              class="fb"
              network="facebook"
              url="https://ussg55inphotos.com/singapore-through-an-american-lens"
              title="From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos"
              hashtags="USSG55"
          >
            <img src="~@/assets/share-fb-dark.svg">
          </ShareNetwork>
          <ShareNetwork
              class="twitter"
              network="twitter"
              url="https://ussg55inphotos.com/singapore-through-an-american-lens"
              title='“From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos” – the virtual exhibition capturing highlights of the friendship, cooperation, and inspiration between the United States and Singapore through 55 photos.'
              hashtags="USSG55"
          ><img src="~@/assets/share-tw-dark.svg">
          </ShareNetwork>
          <a class="email" target="_blank" href='mailto:?subject=From Sea to Shining Sea: 55 Years of U.S.-Singapore Relations in Photos&body=George W. Porter was an American diplomat at the U.S. Embassy in Singapore from 1965 to 1970. During his lunch hours and leisure time, he took photographs of everyday life, particularly the people and activities at the Singapore River, which was a short walk from the Embassy building at Hill Street.%0D%0ACheck out “From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos” – the virtual exhibition capturing highlights of the friendship, cooperation, and inspiration between the United States and Singapore through 55 photos. https://ussg55inphotos.com #USSG55'><img src="~@/assets/share-mail-dark.svg"></a>
          <div class="clear-float"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Singapore",
  methods: {
    goBack() {
      this.$router.push('explore');
    }
  }
}
</script>

<style scoped>

</style>