<template>
  <div class="theme bg-main">
    <div class="theme-landing">
      <div class="go-back" @click="goBack">
        <img src="~@/assets/arrow-dark.svg">
        <span>Gallery</span>
      </div>
      <img class="theme-captain" src="~@/assets/_music/Theme-Musical-Stars.svg">
      <div class="theme-words">
        <p>American music has long been popular in Singapore, from jazz in the pre-war years to disco, pop, rock, hip-hop and more today. In the 1960s, the U.S. Information Service held events for local musicians, such as a popular jazz series from 1963 to 1965 at the Victoria Concert Hall and Victoria Theatre. It also sponsored American musicians and singers to perform in Singapore.</p><br>
        <p>Singaporeans not only appreciated American music, they started performing and writing their own compositions. The 1960s saw a wave of commercially successful local bands who were inspired by American and British popular music. From the 1980s, Singaporean Margaret Leng Tan was a notable figure in the American avant-garde music scene, and other Singaporean musicians and bands later made their mark in fields like pop and rock.</p>
      </div>
      <div class="scroll-hint">
        <img src="~@/assets/arrow-dark.svg">
        <p>Scroll to explore</p>
      </div>
    </div>
    <div class="theme-content">
      <div class="image-full">
        <img  src="~@/assets/_music/1964-Louis-Armstrong.jpg">
      </div>
      <div class="right-text">
        <div class="right-text-block text-block">
          <p>In November 1964, American jazz musician extraordinaire Louis Armstrong performed in Singapore. This photo with local jazz lovers was taken at a reception in his honor and includes Jeremy Soliano, Tony Castillo, Billy Martinez, Rufino Soliano, Jimmy Gan, and Horace Wee.</p><br>
          <p>Image credit: U.S. Embassy Singapore</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-left-text">
        <div class="image-block">
          <img src="~@/assets/_music/1962-Zubir-Said-New-York-Woodwind-Quintet.jpg">
        </div>
        <div class="text-block">
          <p>Zubir Said (right), the composer of Singapore’s national anthem, “Majulah Singapura,” discussed a local bamboo musical instrument with a member of the New York Woodwind Quintet. The group performed at the Victoria Memorial Hall in Singapore in September 1962 at an event sponsored by the U.S. Information Service (USIS).</p><br>
          <p>Image credit: U.S. Embassy Singapore</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-right-text">
        <div class="image-block">
          <img src="~@/assets/_music/1969-Eartha-Kitt.jpg">
        </div>
        <div class="text-block">
          <p>American singer and actor Eartha Kitt performed for three nights at the National Theatre in Singapore in March 1969. In the 1950s, she became renowned for her distinctive recordings of the songs “C’est si bon” and “Santa Baby.” Her music was popular with young English-speaking Singaporeans and frequently played on local radio.</p><br>
          <p>Source: The Straits Times © SPH Media Limited. Permission required for reproduction.</p><br><br>
          <p>Read about <a href="https://eresources.nlb.gov.sg/newspapers/
Digitised/Article/straitstimes19690307-1.2.136" target="_blank">Eartha Kitt’s interview with the press</a> in Singapore</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-full">
        <img  src="~@/assets/_music/1972-Duke-Ellington.jpg">
      </div>
      <div class="right-text">
        <div class="right-text-block text-block">
          <p>The great American jazz musician Duke Ellington gave a one-night only performance at the National Theatre in Singapore on February 2, 1972. He began his career in the 1920s and composed more than a thousand compositions, many of which have become jazz standards. The Duke Ellington Orchestra, which he founded, still performs today and last played in Singapore in 2018 at the Singapore International Festival of the Arts.</p><br>
          <p>Source: The Straits Times © SPH Media Limited. Permission required for reproduction.</p><br><br>
          <p>Read about <a href="https://eresources.nlb.gov.sg/newspapers/
Digitised/Article/newnation19720203-1.2.26" target="_blank">the Duke’s performance in Singapore</a> in 1972</p><br><br>
          <p>Check out some photos of <a href="https://twitter.com/redwhitebluedot/status/996275613378994177?lang=en" target="_blank">the Duke Ellington Orchestra’s performance in Singapore</a> in 2018</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-left-text">
        <div class="image-block">
          <img src="~@/assets/_music/Margaret-Leng-Tan.jpg">
        </div>
        <div class="text-block">
          <p>Singaporean musician Margaret Leng Tan was the first woman to earn a doctorate
            from the Juilliard School in New York in 1971. She collaborated with her mentor, the
            leading American avant-garde composer John Cage, from 1981 until his death in 1992.
            A Singapore Cultural Medallion and US National Endowment for the Arts award
            recipient,
            Tan continues to break new ground in experimental music, using toy pianos
            and incorporating theater and choreography in her work.</p><br>
          <p>Image credit: Michael Dames</p><br><br>
          <p>Watch <a href="https://www.youtube.com/watch?v=aaZGMb6kPX8" target="_blank">Tan’s performance at the National Gallery Singapore</a> in 2021</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="share-block">
        <p>Like this photo collection? Share it with a friend!</p>
        <div class="share-icons">
          <ShareNetwork
              class="fb"
              network="facebook"
              url="https://ussg55inphotos.com/musical-stars"
              title="From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos"
              hashtags="USSG55"
          >
            <img src="~@/assets/share-fb-dark.svg">
          </ShareNetwork>
          <ShareNetwork
              class="twitter"
              network="twitter"
              url="https://ussg55inphotos.com/musical-stars"
              title='“From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos” – the virtual exhibition capturing highlights of the friendship, cooperation, and inspiration between the United States and Singapore through 55 photos.'
              hashtags="USSG55"
          ><img src="~@/assets/share-tw-dark.svg">
          </ShareNetwork>
          <a class="email" target="_blank" href='mailto:?subject=From Sea to Shining Sea: 55 Years of U.S.-Singapore Relations in Photos&body=American music has long been popular in Singapore, from jazz in the pre-war years to disco, pop, rock, hip-hop and more today.%0D%0ACheck out “From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos” – the virtual exhibition capturing highlights of the friendship, cooperation, and inspiration between the United States and Singapore through 55 photos. https://ussg55inphotos.com #USSG55'><img src="~@/assets/share-mail-dark.svg"></a>
          <div class="clear-float"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MusicalStars",
  methods: {
    goBack() {
      this.$router.push('explore');
    }
  }
}
</script>

<style scoped>

</style>