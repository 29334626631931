<template>
  <div class="theme bg-navy further-research">
    <div class="theme-landing">
      <div class="wave-top">
        <img src="~@/assets/wave-desktop-1.svg">
      </div>
      <div class="wave-bottom">
        <img src="~@/assets/wave-desktop-2.svg">
      </div>
      <div class="go-back" @click="goBack">
        <img src="~@/assets/arrow-light.svg">
        <span>Gallery</span>
      </div>
      <div class="learn-block">
        <div class="learn-content">
          <p class="title">Further Research</p><br>
          <p class="bold">In partnership with NTU School of Art, Design and Media Library</p>
          <p>Coming soon.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FurtherResearch",
  data() {
    return {
    }
  },
  methods: {
    goBack() {
      this.$router.push('explore');
    },
  }
}
</script>

<style scoped>

</style>