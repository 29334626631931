<template>
  <div class="about">
    <div class="about-image">
      <img src="~@/assets/masthead.svg">
    </div>
    <div class="about-text-block">
      <div class="about-text-block-content">
        <p class="about-title">About</p>
        <p class="about-text">On April 4, 1966, the United States and Singapore established formal diplomatic relations, less than one year after Singapore became an independent nation. Americans, however, had been coming and going from the island since the British started a colonial trading port in 1819. First came missionaries and traders, then came companies that became household names, such as Citibank, Goodyear, and Ford.</p><br>
        <p class="about-text">After the Second World War, American culture was popular with young people in Singapore. Business and political relationships continued to grow. Today, more than 30,000 Americans live in Singapore, and the two countries have deep economic, cultural, and security ties. “From sea to shining sea,” a line from the song “America the Beautiful,” reflects the U.S.-Singapore exchanges that have developed and deepened over time. This photography exhibition captures some highlights of the 55 years of friendship, cooperation, and inspiration between the two countries.</p>
        <a class="about-download" href="/documents/USSG55-photo-list.pdf" target="_blank">Download a PDF list of the 55 photos <img src="~@/assets/download.svg"></a>
        <p class="about-title">About the music</p>
        <p>The music for the website is an instrumental reinterpretation
          of “American the Beautiful” by Ray Charles, produced by three Singaporean musicians: sound designer Ramesh Krishnan, guitarist Munir Alsagoff on a classic Fender Telecaster, and percussionist Mohamed Noor on a handpan drum. The music incorporates the sounds of the seas, an American blues guitar style, and the multicultural handpan used in Singapore, evoking Singapore’s multicultural society and connection with the United States.</p>
        <p class="about-title about-us">About U.S. Embassy Singapore</p>
        <p>U.S. Embassy Singapore consists of 24 U.S. Federal Government agencies working in Singapore and the region. The U.S. Embassy works to maintain a strong bilateral relationship with the Government of Singapore and to increase the security and prosperity of Americans at home and in Southeast Asia. Additional information about the U.S. Embassy in Singapore can be found at this <a href="https://sg.usembassy.gov/" target="_blank">website</a>.</p>
        <div class="share-logos">
          <div class="share-logo-block">
            <a href="https://www.facebook.com/singapore.usembassy" target="_blank"><img src="~@/assets/share-fb-dark.svg"></a>
            <a href="https://twitter.com/RedWhiteBlueDot" target="_blank"><img src="~@/assets/share-tw-dark.svg"></a>
            <a href="https://www.instagram.com/usembassysingapore/" target="_blank"><img src="~@/assets/social-IG.svg"></a>
            <a href="https://www.youtube.com/user/singaporeusembassy" target="_blank"><img src="~@/assets/social-YT.svg"></a>
            <div class="clear-float"></div>
          </div>
          <div class="share-logo-block">
            <a class="contact-us" href="mailto:SingaporePASCultural@state.gov" target="_blank"><img class="about-email" src="~@/assets/share-mail-dark.svg"> Contact us</a>
            <div class="clear-float"></div>
          </div>
        </div>
        <p class="about-title about-credits">Credits</p>
        <div class="logos">
          <img src="~@/assets/logos.png">
          <p class="position">Supported by</p>
          <div class="side-by-side">
            <img src="~@/assets/logo-SPH.png">
            <img src="~@/assets/logo-NLB.png">
          </div>
          <p class="position">Other supporters</p>
          <img class="ntu" src="~@/assets/logo-NTU.png">
          <div class="side-by-side">
            <img src="~@/assets/logo-AAS.png">
            <img src="~@/assets/logo-NMS.png">
          </div>
        </div>
        <p class="position">Project manager</p>
        <p class="name">Hera</p>
        <p class="position">Writer, curator and researcher</p>
        <p class="name">Yu-Mei Balasingamchow</p>
        <p class="position">Designer</p>
        <p class="name">Lim Shu Min</p>
        <p class="position">Sound designer</p>
        <p class="name">Ramesh Krishnan</p>
        <p class="position">Musicians</p>
        <p class="name">Munir Alsagoff, Mohamed Noor</p>
        <p class="position">Web developer</p>
        <p class="name">Mao Yuncheng</p>
        <p class="position">Advisors</p>
        <p class="name">Laura Miotto, Dr Paul Taylor</p>
        <div class="survey">
          <p>We love to have your feedback! Share your thoughts about the exhibition in this <a href="https://forms.gle/zNPtBxQXFmDqnFUo7" target="_blank">survey</a>!</p>
        </div>
      </div>
      <div class="clear-float"></div>
    </div>
    <div class="close-button" @click="goBack()">
      <img src="~@/assets/close.svg">
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  methods: {
    goBack() {
      this.$emit('close');
    }
  }
}
</script>
