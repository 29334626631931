<template>
  <div class="theme bg-pink learn-together">
    <div class="theme-landing">
      <div class="wave-top">
        <img src="~@/assets/wave-desktop-1.svg">
      </div>
      <div class="wave-bottom">
        <img src="~@/assets/wave-desktop-2.svg">
      </div>
      <div class="go-back" @click="goBack">
        <img src="~@/assets/arrow-dark.svg">
        <span>Gallery</span>
      </div>
      <div class="learn-block">
        <div class="learn-content">
          <p class="title">Let’s learn together!</p><br>
          <p class="bold">Educational classroom activities designed in partnership with
            St Joseph’s Institution (Independent)</p>
          <p>We hope you have enjoyed browsing the gallery space as much
            as we have. Wondering how you can use the photographs for
            some fun, learning activities? We have designed two activities
            just for you! Have fun!</p>
          <p class="bold activity">ACTIVITY 1</p><br>
          <p class="title">Photo tableaux</p><br>
          <p>Photos capture a slice of time. What do you think happens next? Stage the next few scenes with your friends and make some photos.</p>
          <p class="bold download">Download this activity in PDF <a href="/documents/USSG55_ClassroomActivity_PhotoTableaux.pdf" target="_blank"><img src="~@/assets/download.svg"></a>or PPT<a href="/documents/USSG55_ClassroomActivity_PhotoTableaux.pptx" target="_blank"><img src="~@/assets/download.svg"></a></p>
          <p class="bold educator-note" @click="toggleExpand1">Educator’s Note <img :class="{ 'expand-up': true, 'expand-down': expand1 }" src="~@/assets/expand.svg"></p>
          <p :class="{'expand-close': !expand1}">This lesson activity is intended for middle school students to extend
            the story from the chosen scene. Students can use the storyboard
            frames to create a sequence of events using drawings, photographs
            or even through a paper collage with magazine cut outs. You might
            want to ask students to think about what the characters might be
            thinking, saying and feeling in the events that happen after.</p>
<!--          <div class="square-two">-->
<!--            <div class="ballet">-->
<!--              <div class="ballet-text">-->
<!--                <p>San Francisco Ballet at Queen Elizabeth Walk with the former Fullerton Building, 1960.</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class=""></div>-->
<!--            <div class="speech2"><img src="~@/assets/speech-2.svg"></div>-->
<!--          </div>-->
<!--          <div class="square-three">-->
<!--            <div class=""></div>-->
<!--            <div class=""></div>-->
<!--            <div class=""></div>-->
<!--            <div class="speech1"><img src="~@/assets/speech-1.svg"></div>-->
<!--          </div>-->
          <div class="ballet-img">
            <img class="desktop" src="~@/assets/tableaux-desktop.png">
            <img class="mobile" src="~@/assets/tableaux-mobile.png">
          </div>
          <p class="bold activity2">ACTIVITY 2</p>
          <p class="title">Let’s analyse a photograph</p>
          <p class="bold">Recommended ages: 16–18 year olds</p>
          <p>Art critics study works of art through a critical analysis of an
            artwork’s seemingly intangible aspects to help others
            understand them in more tangible ways. The Feldman Approach
            is a famous, four-step method consisting of (1) description,
            (2) formal analysis, (3) interpretation, and (4) judgement.
            Following this method allows one to express one’s analysis and
            understanding of the chosen work in a thorough and detailed
            manner.</p><br>
          <p>Choose one of the photographs available in the collection and
            give the analysis a go!</p>
          <p class="bold download">Download this activity in PDF <a href="/documents/USSG55_ClassroomActivity_PhotoAnalysis.pdf" target="_blank"><img src="~@/assets/download.svg"></a>or PPT<a href="/documents/USSG55_ClassroomActivity_PhotoAnalysis.pptx" target="_blank"><img src="~@/assets/download.svg"></a></p>
          <p class="bold educator-note" @click="toggleExpand2">Educator’s Note <img :class="{ 'expand-up': true, 'expand-down': expand2 }" src="~@/assets/expand.svg"></p>
          <p :class="{'expand-close': !expand2}">This lesson activity is intended for high school students to follow
            the Feldman Approach to analyse a photograph of their choice.
            Remember that the analysis need to be justified with visual elements
            that they identify in the Describe step, as well as with the Principles
            of Design that they are able to tease out in the Analyse step. For
            Interpret, encourage students to find out some context behind why
            and how the photograph was made. The analysis ends with an
            evaluative Judge step, so invite your students to think about the
            effectiveness of the photo in achieving its intended aims.</p>
          <div class="square-four">
            <div class="square-four-grid">
              <p>Describe:</p>
              <p>What do you see in the photograph?</p>
            </div>
            <div class="square-four-grid">
              <p>Analyse:</p>
              <p>How is the photograph organised?</p>
            </div>
            <div class="square-four-grid">
              <p>Interpret:</p>
              <p>What is the photographer trying to convey?</p>
            </div>
            <div class="square-four-grid">
              <p>Judge:</p>
              <p>What do you like or dislike about the photo?</p>
            </div>
            <div class="clear-float"></div>
          </div>
          <p class="bold camera">
            <img src="~@/assets/camera.svg">&nbsp;Think!
          </p>
          <p class="end">Photography overtook painting as the go-to medium for
            creatives to express the world around them. From recording
            milestones in history, to capturing a fleeting, precious moment in
            one’s life, photos brought viewers closer than ever to the world
            through the artist’s eyes.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LearnTogether",
  data() {
    return {
      expand1: false,
      expand2: false,
    }
  },
  methods: {
    goBack() {
      this.$router.push('explore');
    },
    toggleExpand1() {
      this.expand1 = !this.expand1;
    },
    toggleExpand2() {
      this.expand2 = !this.expand2;
    },
  }
}
</script>

<style scoped>

</style>