<template>
  <div class="theme bg-main library">
    <div class="theme-landing">
      <div class="wave-top">
        <img src="~@/assets/wave-desktop-1.svg">
      </div>
      <div class="wave-bottom">
        <img src="~@/assets/wave-desktop-2.svg">
      </div>
      <div class="go-back" @click="goBack">
        <img src="~@/assets/arrow-dark.svg">
        <span>Gallery</span>
      </div>
      <div class="learn-block">
        <div class="learn-content">
          <p class="title">Library picks just for you</p><br>
          <p class="bold">In partnership with National Library Board Singapore</p>
          <p class="intro">The #USSG55 reading list is specially curated with National Library Board Singapore.
            Visit their <a href="https://www.nlb.gov.sg/" target="_blank">website</a> to find out more about the resources!</p>
          <p class="im">I am a ...</p>
          <div class="menu desktop">
            <div :class="{'menu-tab': true, 'menu-active': menu===1}" @click="changeMenu(1)">History buff</div>
            <div :class="{'menu-tab': true, 'menu-active': menu===2}" @click="changeMenu(2)">Culture junkie</div>
            <div :class="{'menu-tab': true, 'menu-active': menu===3}" @click="changeMenu(3)">Eagle in the Lion City</div>
            <div class="clear-float"></div>
          </div>
          <div class="menu mobile">
            <select v-model="selectMenu" @change="selectChangeMenu()">
              <option value="1">History buff</option>
              <option value="2">Culture junkie</option>
              <option value="3">Eagle in the Lion City</option>
            </select>
          </div>
          <div v-if="menu === 1" class="shelf-block desktop">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=12130899')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">U.S.-Singapore FTA: Implications and Prospects</p>
                  <p class="author">Tommy Koh</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=4082463')" class="book book-sm">
                <img src="~@/assets/Book-2.svg">
                <div class="text-block">
                  <p class="title">American
                    Foreign Policy:
                    A Global View</p>
                  <p class="author">Henry Kissinger
                  </p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=12345020')" class="book book-md">
                <img src="~@/assets/Book-3.svg">
                <div class="text-block">
                  <p class="title">Free Trade
                    Agreements: U.S.
                    Strategies and
                    Priorities</p>
                  <p class="author">Jeffrey J. Schott</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 1" class="shelf-block desktop">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=203934895')" class="book book-sm">
                <img src="~@/assets/Book-2.svg">
                <div class="text-block">
                  <p class="title">Diplomacy:
                    A Singapore
                    Experience</p>
                  <p class="author">S Jayakumar</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=202727125')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">U.S.-Singapore
                    Relations, 1965–
                    1975: Strategic
                    Non-alignment in the
                    Cold War
                  </p>
                  <p class="author">Daniel Chua</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=13717250')" class="book book-sp">
                <img src="~@/assets/Book-4.svg">
                <div class="text-block">
                  <p class="title">The Obama Promise:
                    Challenges and
                    Opportunities for Asia</p>
                  <p class="author">José Ramos-Horta</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 1" class="shelf-block desktop">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=14203666')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">U.S.-Singapore Free
                    Trade Agreement
                    after Five Years</p>
                  <p class="author">Robert E. Flemchak
                  </p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=204325727')" class="book book-sp">
                <img src="~@/assets/Book-4.svg">
                <div class="text-block">
                  <p class="title">Crossroads: A Popular
                    History of Malaysia and
                    Singapore</p>
                  <p class="author">Jim Baker</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=205319238')" class="book book-md">
                <img src="~@/assets/Book-3.svg">
                <div class="text-block">
                  <p class="title">America in the
                    World: A History
                    of U.S. Diplomacy
                    and Foreign Policy</p>
                  <p class="author">Robert B Zoellick</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 1" class="shelf-block desktop">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=14227236')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">The U.S.-Singapore
                    Free Trade
                    Agreement: An
                    American
                    Perspective on Power,
                    Trade and Security in
                    the Asia Pacific
                  </p>
                  <p class="author">Eul-Soo Pang</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=13174018')" class="book book-sm">
                <img src="~@/assets/Book-2.svg">
                <div class="text-block">
                  <p class="title">Obama’s Asian
                    Policy: Change
                    and Continuity
                  </p>
                  <p class="author">You Ji</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=84444427')" class="book book-md">
                <img src="~@/assets/Book-3.svg">
                <div class="text-block">
                  <p class="title">U.S. Policy in the
                    Asia-Pacific
                    region: Meeting
                    the Challenges of
                    the Post Cold-War
                    Era
                  </p>
                  <p class="author">George Bush</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 1" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=12130899')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">U.S.-Singapore FTA: Implications and Prospects</p>
                  <p class="author">Tommy Koh</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=4082463')" class="book book-sm">
                <img src="~@/assets/Book-2.svg">
                <div class="text-block">
                  <p class="title">American
                    Foreign Policy:
                    A Global View</p>
                  <p class="author">Henry Kissinger
                  </p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 1" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=12345020')" class="book book-md">
                <img src="~@/assets/Book-3.svg">
                <div class="text-block">
                  <p class="title">Free Trade
                    Agreements: U.S.
                    Strategies and
                    Priorities</p>
                  <p class="author">Jeffrey J. Schott</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 1" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=203934895')" class="book book-sm">
                <img src="~@/assets/Book-2.svg">
                <div class="text-block">
                  <p class="title">Diplomacy:
                    A Singapore
                    Experience</p>
                  <p class="author">S Jayakumar</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=202727125')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">U.S.-Singapore
                    Relations, 1965–
                    1975: Strategic
                    Non-alignment in the
                    Cold War
                  </p>
                  <p class="author">Daniel Chua</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 1" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=13717250')" class="book book-sp">
                <img src="~@/assets/Book-4.svg">
                <div class="text-block">
                  <p class="title">The Obama Promise:
                    Challenges and
                    Opportunities for Asia</p>
                  <p class="author">José Ramos-Horta</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 1" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=14203666')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">U.S.-Singapore Free
                    Trade Agreement
                    after Five Years</p>
                  <p class="author">Robert E. Flemchak
                  </p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=204325727')" class="book book-sp">
                <img src="~@/assets/Book-4.svg">
                <div class="text-block">
                  <p class="title">Crossroads: A Popular
                    History of Malaysia and
                    Singapore</p>
                  <p class="author">Jim Baker</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 1" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=205319238')" class="book book-md">
                <img src="~@/assets/Book-3.svg">
                <div class="text-block">
                  <p class="title">America in the
                    World: A History
                    of U.S. Diplomacy
                    and Foreign Policy</p>
                  <p class="author">Robert B Zoellick</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 1" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=14227236')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">The U.S.-Singapore
                    Free Trade
                    Agreement: An
                    American
                    Perspective on Power,
                    Trade and Security in
                    the Asia Pacific
                  </p>
                  <p class="author">Eul-Soo Pang</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=13174018')" class="book book-sm">
                <img src="~@/assets/Book-2.svg">
                <div class="text-block">
                  <p class="title">Obama’s Asian
                    Policy: Change
                    and Continuity
                  </p>
                  <p class="author">You Ji</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 1" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=84444427')" class="book book-md">
                <img src="~@/assets/Book-3.svg">
                <div class="text-block">
                  <p class="title">U.S. Policy in the
                    Asia-Pacific
                    region: Meeting
                    the Challenges of
                    the Post Cold-War
                    Era
                  </p>
                  <p class="author">George Bush</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 1" class="shelf-block desktop">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=84444427')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">Singapore and Free
                    Trade Agreements:
                    Economic Relations
                    with Japan and the
                    United States</p>
                  <p class="author">Ramkishen S. Rajan,
                    Rahul Sen, Reza
                    Siregar</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 1" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=84444427')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">Singapore and Free
                    Trade Agreements:
                    Economic Relations
                    with Japan and the
                    United States</p>
                  <p class="author">Ramkishen S. Rajan,
                    Rahul Sen, Reza
                    Siregar</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>

          <div v-if="menu === 2" class="shelf-block desktop">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=13070693')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">Buckminster Fuller: Starting with the Universe</p>
                  <p class="author">K. Michael Hays, Dana Miller</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=201579216')" class="book book-sm">
                <img src="~@/assets/Book-2.svg">
                <div class="text-block">
                  <p class="title">Singapore 60s: An age of discovery</p>
                  <p class="author">George Porter</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=14692996')" class="book book-md">
                <img src="~@/assets/Book-3.svg">
                <div class="text-block">
                  <p class="title">Wit’s End: The Untold Story of the First American Film Made in Singapore</p>
                  <p class="author">Marvin Farkas</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 2" class="shelf-block desktop">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=6212020')" class="book book-sm">
                <img src="~@/assets/Book-2.svg">
                <div class="text-block">
                  <p class="title">American Art in the Residence of the United States Ambassador to Singapore</p>
                  <p class="author"></p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=4078944')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">The Chase Manhattan Bank Collection: American Art in the Sixties and Seventies
                    Singapore National Museum</p>
                  <p class="author"></p>
                </div>
                <div class="book-text">
                  <p>Photo Album</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/data2/BookSG/publish/a/ae64099a-e226-480d-9277-fc7a6b536deb/web/html5/index.html?opf=tablet/BOOKSG.xml&launchlogo=tablet/BOOKSG_BrandingLogo_.png&pn=21')" class="book book-sp">
                <img src="~@/assets/Book-4.svg">
                <div class="text-block">
                  <p class="title">Photobook of Alpha Gallery Exhibitions showing Buckminster Fuller</p>
                  <p class="author"></p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 2" class="shelf-block desktop">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=205219052')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">R. Buckminster Fuller: Pattern-thinking</p>
                  <p class="author">Daniel López-Pérez.</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=12497681')" class="book book-sp">
                <img src="~@/assets/Book-4.svg">
                <div class="text-block">
                  <p class="title">Painted Journey</p>
                  <p class="author">Gregory Burns</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=12647878')" class="book book-md">
                <img src="~@/assets/Book-3.svg">
                <div class="text-block">
                  <p class="title">Your Private Sky: Discourse</p>
                  <p class="author">Buckminster Fuller</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 2" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=13070693')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">Buckminster Fuller: Starting with the Universe</p>
                  <p class="author">K. Michael Hays, Dana Miller</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=201579216')" class="book book-sm">
                <img src="~@/assets/Book-2.svg">
                <div class="text-block">
                  <p class="title">Singapore 60s: An age of discovery</p>
                  <p class="author">George Porter</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 2" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=14692996')" class="book book-md">
                <img src="~@/assets/Book-3.svg">
                <div class="text-block">
                  <p class="title">Wit’s End: The Untold Story of the First American Film Made in Singapore</p>
                  <p class="author">Marvin Farkas</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 2" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=6212020')" class="book book-sm">
                <img src="~@/assets/Book-2.svg">
                <div class="text-block">
                  <p class="title">American Art in the Residence of the United States Ambassador to Singapore</p>
                  <p class="author"></p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=4078944')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">The Chase Manhattan Bank Collection: American Art in the Sixties and Seventies
                    Singapore National Museum</p>
                  <p class="author"></p>
                </div>
                <div class="book-text">
                  <p>Photo Album</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 2" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/data2/BookSG/publish/a/ae64099a-e226-480d-9277-fc7a6b536deb/web/html5/index.html?opf=tablet/BOOKSG.xml&launchlogo=tablet/BOOKSG_BrandingLogo_.png&pn=21')" class="book book-sp">
                <img src="~@/assets/Book-4.svg">
                <div class="text-block">
                  <p class="title">Photobook of Alpha Gallery Exhibitions showing Buckminster Fuller</p>
                  <p class="author"></p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 2" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=205219052')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">R. Buckminster Fuller: Pattern-thinking</p>
                  <p class="author">Daniel López-Pérez.</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=12497681')" class="book book-sp">
                <img src="~@/assets/Book-4.svg">
                <div class="text-block">
                  <p class="title">Painted Journey</p>
                  <p class="author">Gregory Burns</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 2" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=12647878')" class="book book-md">
                <img src="~@/assets/Book-3.svg">
                <div class="text-block">
                  <p class="title">Your Private Sky: Discourse</p>
                  <p class="author">Buckminster Fuller</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 2" class="shelf-block desktop">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=201174423')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">Buckminster Fuller: Anthology for the millennium</p>
                  <p class="author">Thomas T. K. Zung</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 2" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=201174423')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">Buckminster Fuller: Anthology for the millennium</p>
                  <p class="author">Thomas T. K. Zung</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>

          <div v-if="menu === 3" class="shelf-block desktop">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=12643504')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">The Eagle in the Lion City: America, Americans and Singapore</p>
                  <p class="author">Jim Baker</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=10129193')" class="book book-sm">
                <img src="~@/assets/Book-2.svg">
                <div class="text-block">
                  <p class="title">The American Club</p>
                  <p class="author"></p>
                </div>
                <div class="book-text">
                  <p>Periodical</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=13205016')" class="book book-md">
                <img src="~@/assets/Book-3.svg">
                <div class="text-block">
                  <p class="title">Singapore’s Eagles: Singapore American School, 1956–2006</p>
                  <p class="author">Jim Baker</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 3" class="shelf-block desktop">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=202545908 ')" class="book book-sm">
                <img src="~@/assets/Book-2.svg">
                <div class="text-block">
                  <p class="title">Singapore American School</p>
                  <p class="author"></p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=7406823')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">American Chamber Singapore Newsbrief</p>
                  <p class="author">American Chamber of Commerce in Singapore </p>
                </div>
                <div class="book-text">
                  <p>Periodical</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=200115071')" class="book book-sp">
                <img src="~@/assets/Book-4.svg">
                <div class="text-block">
                  <p class="title">Singapore American<br>School Newsletter</p>
                  <p class="author"></p>
                </div>
                <div class="book-text">
                  <p>Periodical</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 3" class="shelf-block desktop">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=4082205')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">Membership directory</p>
                  <p class="author">American Business Council Singapore</p>
                </div>
                <div class="book-text">
                  <p>Directory</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=4079711')" class="book book-sp">
                <img src="~@/assets/Book-4.svg">
                <div class="text-block">
                  <p class="title">AWA directory</p>
                  <p class="author">American Women’s Association</p>
                </div>
                <div class="book-text">
                  <p>Directory</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=200066041')" class="book book-md">
                <img src="~@/assets/Book-3.svg">
                <div class="text-block">
                  <p class="title">Singapore American Football League</p>
                  <p class="author"></p>
                </div>
                <div class="book-text">
                  <p>Periodical</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 3" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=12643504')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">The Eagle in the Lion City: America, Americans and Singapore</p>
                  <p class="author">Jim Baker</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=10129193')" class="book book-sm">
                <img src="~@/assets/Book-2.svg">
                <div class="text-block">
                  <p class="title">The American Club</p>
                  <p class="author"></p>
                </div>
                <div class="book-text">
                  <p>Periodical</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 3" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=13205016')" class="book book-md">
                <img src="~@/assets/Book-3.svg">
                <div class="text-block">
                  <p class="title">Singapore’s Eagles: Singapore American School, 1956–2006</p>
                  <p class="author">Jim Baker</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 3" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=202545908')" class="book book-sm">
                <img src="~@/assets/Book-2.svg">
                <div class="text-block">
                  <p class="title">Singapore American School</p>
                  <p class="author"></p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">American Chamber Singapore Newsbrief</p>
                  <p class="author">American Chamber of Commerce in Singapore</p>
                </div>
                <div class="book-text">
                  <p>Periodical</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 3" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=200115071')" class="book book-sp">
                <img src="~@/assets/Book-4.svg">
                <div class="text-block">
                  <p class="title">Singapore American<br>School Newsletter</p>
                  <p class="author"></p>
                </div>
                <div class="book-text">
                  <p>Periodical</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 3" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=4082205')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">Membership directory</p>
                  <p class="author">American Business Council Singapore</p>
                </div>
                <div class="book-text">
                  <p>Directory</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=4079711')" class="book book-sp">
                <img src="~@/assets/Book-4.svg">
                <div class="text-block">
                  <p class="title">AWA directory</p>
                  <p class="author">American Women’s Association</p>
                </div>
                <div class="book-text">
                  <p>Directory</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 3" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=200066041')" class="book book-md">
                <img src="~@/assets/Book-3.svg">
                <div class="text-block">
                  <p class="title">Singapore American Football League</p>
                  <p class="author"></p>
                </div>
                <div class="book-text">
                  <p>Periodical</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 3" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=202373295')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">The Balestiers: The First American Residents of Singapore</p>
                  <p class="author">R. E. Hale</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=200102650')" class="book book-sm">
                <img src="~@/assets/Book-2.svg">
                <div class="text-block">
                  <p class="title">The Eagle Eye: Singapore American School</p>
                  <p class="author"></p>
                </div>
                <div class="book-text">
                  <p>Periodical</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 3" class="shelf-block desktop">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=202373295')" class="book book-lg">
                <img src="~@/assets/Book-1.svg">
                <div class="text-block">
                  <p class="title">The Balestiers: The First American Residents of Singapore</p>
                  <p class="author">R. E. Hale</p>
                </div>
                <div class="book-text">
                  <p>Book</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=200102650')" class="book book-sm">
                <img src="~@/assets/Book-2.svg">
                <div class="text-block">
                  <p class="title">The Eagle Eye: Singapore American School</p>
                  <p class="author"></p>
                </div>
                <div class="book-text">
                  <p>Periodical</p>
                </div>
              </div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=200021902')" class="book book-md">
                <img src="~@/assets/Book-3.svg">
                <div class="text-block">
                  <p class="title">Building Bridges: Singapore American School</p>
                  <p class="author"></p>
                </div>
                <div class="book-text">
                  <p>Periodical</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
          <div v-if="menu === 3" class="shelf-block mobile">
            <div class="shelf"><div class="liner"></div>
              <div @click="jump('https://eservice.nlb.gov.sg/item_holding.aspx?bid=200021902')" class="book book-md">
                <img src="~@/assets/Book-3.svg">
                <div class="text-block">
                  <p class="title">Building Bridges: Singapore American School</p>
                  <p class="author"></p>
                </div>
                <div class="book-text">
                  <p>Periodical</p>
                </div>
              </div>
              <div class="clear-float"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Library",
  data() {
    return {
      menu: 1,
      selectMenu: 1,
    }
  },
  methods: {
    goBack() {
      this.$router.push('explore');
    },
    changeMenu(menu) {
      if (menu) {
        this.menu = menu;
      }
    },
    selectChangeMenu() {
      this.menu = parseInt(this.selectMenu);
    },
    jump(url) {
      window.open(url, '_blank');
    }
  }
}
</script>

<style scoped>

</style>