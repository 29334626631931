<template>
  <div class="theme bg-navy">
    <div class="theme-landing">
      <div class="go-back" @click="goBack">
        <img src="~@/assets/arrow-light.svg">
        <span>Gallery</span>
      </div>
      <img class="theme-captain" src="~@/assets/_military/Theme-Security-Cooperation.svg">
      <div class="theme-words">
        <p>The United States and Singapore cooperate on security issues such as border security, maritime security, military preparedness, counter proliferation, cybersecurity, and counterterrorism. Singapore provides critical access for U.S. military units deployed to the region and hosts nearly 1,000 U.S. service members, civilians, and dependents. Singapore also has the second largest military presence in the United States of any foreign partner, with more than 1,000 Singaporean military personnel participating in training, exercises, and professional military education there.
        </p><br>
        <p>In recent years, Singapore has deepened its defense ties with the United States by renewing its 1990 MOU regarding U.S. use of military facilities in Singapore, as well as establishing a Republic of Singapore Air Force (RSAF) Fighter Training Detachment in Guam and finalizing an MOU on cyber cooperation.</p>
      </div>
      <div class="scroll-hint">
        <img src="~@/assets/arrow-light.svg">
        <p>Scroll to explore</p>
      </div>
    </div>
    <div class="theme-content">
      <div class="image-full">
        <img  src="~@/assets/_military/2019-Valiant-Mark.jpg">
      </div>
      <div class="left-text">
        <div class="left-text-block text-block">
          <p>A member of the Singapore Armed Forces introduced a U.S. Marine to a durian during Exercise Valiant Mark 2019 on Pulau Tekong in Singapore. Singapore and the United States have a strong bilateral defense partnership. For example, 2021 marked the 40th year of Exercise Tiger Balm between the two countries.</p><br>
          <p>Photo courtesy of the U.S. Department of Defense</p><br><br>
          <p>Learn more about <a href="https://sg.usembassy.gov/fact-sheets/" target="_blank">defense cooperation between the U.S.
            and Singapore</a></p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-full">
        <img  src="~@/assets/_military/2010-Valiant-Mark.jpg">
      </div>
      <div class="left-text">
        <div class="left-text-block text-block">
          <p>Held annually since 1991, Exercise Valiant Mark is conducted by the U.S. Marine Corps and the Singapore Armed Forces (SAF). This picture, taken in 2010, shows U.S. Marines from Company A “Animal,” 1st Battalion 7th Marines along with soldiers from 1st Battalion Singapore Guards assaulting an “enemy” compound at the Murai Urban Training Facility in Singapore.</p><br>
          <p>Photo courtesy of the U.S. Department of Defense</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-left-text">
        <div class="image-block">
          <img src="~@/assets/_military/2012-Naval-Academy-Sam-Tan.jpg">
        </div>
        <div class="text-block">
          <p>In 2012, Republic of Singapore Navy officer Sam Tan Wei Shen topped his class at the U.S. Naval Academy. He was the first foreign midshipman to achieve this honor in the institution’s 167-year history.</p><br>
          <p>Image credit: U.S. Naval Academy</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-right-text">
        <div class="image-block">
          <img src="~@/assets/_military/2014-CARAT.jpg">
        </div>
        <div class="text-block">
          <p>In this photo from 2014, Lieutenant (junior grade) Katherine Smith (extreme left) on the guided-missile destroyer USS Wayne E. Meyer discussed its electrical plant and propulsion controls with engineering personnel from the Republic of Singapore Navy (RSN). This took place during Cooperation Afloat Readiness and Training (CARAT), an annual bilateral exercise with the U.S. Navy, U.S. Marine Corps, and the armed forces of nine partner nations, including Singapore. </p><br>
          <p>Photo courtesy of the U.S. Department of Defense</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-full">
        <img  src="~@/assets/_military/2021-RSAF-Nellis-Air-Force-Base.jpg">
      </div>
      <div class="left-text">
        <div class="left-text-block text-block">
          <p>More than 1,000 Singaporean military personnel participate in training, exercises, and professional military education in the United States. Pictured are Republic of Singapore Air Force (RSAF) pilots and contractor who participated in the Red Flag exercise at Nellis Air Force Base in Nevada in 2021. They trained in advanced aerial combat scenarios with the U.S. Air Force, Sweden, and several NATO nations.</p><br>
          <p>Photo courtesy of the U.S. Department of Defense</p><br><br>
          <p>Learn more about <a href="https://www.youtube.com/watch?v=50V1JQp19NE" target="_blank">the RSAF’s Air Force Engineer team</a> that clinched the “Outstanding Maintenance Unit” award at Red Flag in 2021</p><br><br>
          <p>Watch the <a href="https://www.dvidshub.net/video/548539/428th-fighter-squadron-participates-red-flag-17-4-w-o-graphics" target="_blank">428th fighter squadron</a>, which trains RSAF pilots, participate in Red Flag in 2021</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="share-block">
        <p>Like this photo collection? Share it with a friend!</p>
        <div class="share-icons">
          <ShareNetwork
              class="fb"
              network="facebook"
              url="https://ussg55inphotos.com/strength-in-security-cooperation"
              title="From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos"
              hashtags="USSG55"
          >
            <img src="~@/assets/share-fb-light.svg">
          </ShareNetwork>
          <ShareNetwork
              class="twitter"
              network="twitter"
              url="https://ussg55inphotos.com/strength-in-security-cooperation"
              title='“From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos” – the virtual exhibition capturing highlights of the friendship, cooperation, and inspiration between the United States and Singapore through 55 photos.'
              hashtags="USSG55"
          ><img src="~@/assets/share-tw-light.svg">
          </ShareNetwork>
          <a class="email" target="_blank" href='mailto:?subject=From Sea to Shining Sea: 55 Years of U.S.-Singapore Relations in Photos&body=The United States and Singapore cooperate on various security issues such as border security, maritime security, military preparedness, counter proliferation, cybersecurity, and counterterrorism.%0D%0ACheck out “From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos” – the virtual exhibition capturing highlights of the friendship, cooperation, and inspiration between the United States and Singapore through 55 photos. https://ussg55inphotos.com #USSG55'><img src="~@/assets/share-mail-light.svg"></a>
          <div class="clear-float"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Security",
  methods: {
    goBack() {
      this.$router.push('explore');
    }
  }
}
</script>