<template>
  <div class="theme bg-pink">
    <div class="theme-landing">
      <div class="go-back" @click="goBack">
        <img src="~@/assets/arrow-dark.svg">
        <span>Gallery</span>
      </div>
      <img class="theme-captain" src="~@/assets/_culture/Theme-Culture-In-Action.svg">
      <div class="theme-words">
        <p>Although American music and movies seem to dominate Singapore’s popular culture today, there have been many moments of two-way cultural exchange over the years. In the 1950s, before many Singaporeans could easily and cheaply access American culture, the U.S. Information Service (USIS) at Raffles Place provided a library and reading room, as well as sponsored exhibitions and performances.</p><br>
        <p>Over the years, the American Association of Singapore, American Club, and Singapore American School have been instrumental in fostering cultural interactions between Americans and Singaporeans. Today, more than 30,000 Americans live in Singapore. American architects have left their mark on Singapore’s skyline. People from the two countries have played and competed in sports, enjoyed and created music and art together, and influenced each other’s views of the world.</p>
      </div>
      <div class="scroll-hint">
        <img src="~@/assets/arrow-dark.svg">
        <p>Scroll to explore</p>
      </div>
    </div>
    <div class="theme-content">
      <div class="image-full">
        <img  src="~@/assets/_culture/1960-San-Francisco-Ballet.jpg">
      </div>
      <div class="right-text">
        <div class="right-text-block text-block">
          <p>In 1960, the San Francisco Ballet performed at the Victoria Theatre, an event described as Singapore’s “cultural event of the year.” Five members of the Ballet are pictured here posing for TV and publicity pictures at Queen Elizabeth Walk, with the former Fullerton Building (now Fullerton Hotel) in the background.</p><br>
          <p>Image credit: U.S. Embassy Singapore</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-left-text">
        <div class="image-block">
          <img src="~@/assets/_culture/1950s-USIS-Library.jpg">
        </div>
        <div class="text-block">
          <p>The first U.S. Information Service (USIS) offices and library in Singapore were located on the second floor of 26 Raffles Place. This photograph shows the reading room in the 1950s, when it was popular with young people who wanted access to American publications or to learn more about the United States.</p><br>
          <p>Image credit: U.S. Embassy Singapore</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-full">
        <img  src="~@/assets/_culture/1965-Man-In-Space.jpg">
      </div>
      <div class="left-text">
        <div class="left-text-block text-block">
          <p>In April 1965, the U.S. Information Service (USIS) held an exhibition, “U.S. Man in Space,” in the foyer of the National Library at Stamford Road. On display was a model of the Gemini spacecraft, which had the previous month made its first successful crewed flight carrying two American astronauts to space.</p><br>
          <p>Image credit: U.S. Embassy Singapore</p><br><br>
          <p>Did you know that <a href="https://www.science.edu.sg/whats-on/exhibitions/past-exhibitions/moonshot-50" target="_blank">the Singapore Science Centre has a moon rock</a> from the Apollo 11 landing?</p><br><br>
          <p>Hear from <a href="https://www.youtube.com/playlist
?list=PL64pfLZ8XYrGfRPuaJuPPY4I9pJ3D377N" target="_blank">Singaporeans who remember watching the Moon landing</a> in 1969</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-right-text">
        <div class="image-block">
          <img  src="~@/assets/_culture/Hoe-Juan-Sim-SAS.jpg">
        </div>
        <div class="text-block">
          <p>The Ho family (also spelled “Hoe”) has been running the dining service at the Singapore American School since 1965. They serve both local and American food. School alumni describe their curry, sweet and sour chicken, and Szechuan chicken as some of their fondest food memories in Singapore.</p><br>
          <p>Image credit: The family of Ho Juan Sim</p><br><br>
          <p>Read more about <a href="https://www.sas.edu.sg/about-us/publications-and-media/perspectives/post-details/~board/perspectives-blog/post/in-loving-memory-mr-ho-juan-sim" target="_blank">the Singapore American School’s loving memories of Mr. Ho Juan Sim</a></p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-left-text">
        <div class="image-block">
          <img src="~@/assets/_culture/1977-Mickey-Minnie-Mouse.jpg">
        </div>
        <div class="text-block">
          <p>Disneyland’s Mickey and Minnie Mouse visited Singapore to celebrate the United States’ 201st Independence Day with the American community. Accompanying them was Disneyland’s 1977 ambassador, Susan Donald (center). Besides appearing at the Singapore American School, the mascots also brought good cheer to hospitals, welfare homes for children, and the Singapore Association for the Deaf.</p><br>
          <p>Source: The Straits Times © SPH Media Limited. Permission required for reproduction.</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-right-text">
        <div class="image-block">
          <img  src="~@/assets/_culture/1973-Harlem-Globetrotters.jpg">
        </div>
        <div class="text-block">
          <p>The Harlem Globetrotters, an American basketball team, made their first visit to Singapore in 1952, delighting audiences with their brand of sporting flair and entertainment style. They played four games before an audience of about ten thousand and returned a number of times in subsequent decades. This photograph was taken in 1973 at Gay World Stadium.</p><br>
          <p>Source: The Straits Times © SPH Media Limited. Permission required for reproduction.</p><br><br>
          <p>Watch <a href="https://www.razor.tv/video/harlem-globetrotters-thrill-fans-in-singapore/4800266888001/6022787022001" target="_blank">the Harlem Globetrotters play</a> the Washington Generals in Singapore in 2019</p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-full">
        <img src="~@/assets/_culture/1980-Paul-Rudolph-Concourse.jpg">
      </div>
      <div class="right-text">
        <div class="right-text-block text-block">
          <p>Paul Rudolph was a prominent modernist American architect who explored scale and modularity in his designs. In the 1980s, he designed the Colonnade, a condominium at Grange Road, and the Concourse (pictured), an office and shopping complex at Beach Road. For the latter, Rudolph used atriums and balconies to create a distinctive silhouette. The curtain wall pictured was rebuilt to the likeness of Rudolph’s design for the Concourse.</p><br>
          <p>Image credit: Darren Soh</p><br><br>
          <p>Learn more about Paul Rudolph’s design for the <a href="https://www.paulrudolphheritagefoundation.org/198104-office-and-shopping-complex" target="_blank">Concourse</a> and the <a href="https://www.paulrudolphheritagefoundation.org/198001-colonnade" target="_blank">Colonnade</a></p><br><br>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-left-text">
        <div class="image-block">
          <img src="~@/assets/_culture/1990-IM-Pei-Gateway.jpg">
        </div>
        <div class="text-block">
          <p>I.M. Pei (full name Ieoh Ming Pei) was a modernist American architect, perhaps best known for designing the Louvre Pyramid. In Singapore, his buildings have become iconic landmarks: OCBC Centre at Chulia Street, Raffles City, and the Gateway (pictured). The latter, like many of his works, is noted for its precise geometry.</p><br>
          <p>Image credit: Darren Soh</p><br><br>
          <p>Learn more about <a href="https://www.youtube.com/watch?v=KmdnmpRZlDE" target="_blank">I.M. Pei’s buildings in Singapore</a></p>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="image-full">
        <img  src="~@/assets/_culture/2003-Lee-Wen-Strange-Fruit.jpg">
      </div>
      <div class="left-text">
        <div class="left-text-block text-block">
          <p>In 2016, the U.S. Embassy Singapore awarded the Joseph Balestier Award for the Freedom of Art to Singaporean artist Lee Wen. The award is for artists who “push the boundaries of creativity and challenge constraints to freedom of expression.” Lee was a pioneering performance artist who was critically well-regarded for his <span class="italic">Yellow Man</span> works (1992–2001). Pictured is <span class="italic">Strange Fruit</span> (2003).</p><br>
          <p>Image courtesy of the artist and National Heritage Board</p><br><br>
          <p class="disclaimer">Disclaimer: The copyright of the artwork belongs to the artist and their authorised representatives and may not be reproduced for any purpose whatsoever without the prior written permission of the National Heritage Board and/or copyright owners.</p><br><br>
          <p>Explore <a href="https://aaa.org.hk/en/collections/search/archive/lee-wen-archive" target="_blank">the artworks and career of Lee Wen</a></p><br><br>
          <p>Learn more about <a href="https://eresources.nlb.gov.sg/infopedia/articles/
SIP_951_2004-12-23.html
" target="_blank">Joseph Balestier, the first American consul to Singapore</a></p><br><br>
        </div>
        <div class="clear-float"></div>
      </div>
      <div class="share-block">
        <p>Like this photo collection? Share it with a friend!</p>
        <div class="share-icons">
          <ShareNetwork
              class="fb"
              network="facebook"
              url="https://ussg55inphotos.com/culture-in-action"
              title="From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos"
              hashtags="USSG55"
          >
            <img src="~@/assets/share-fb-dark.svg">
          </ShareNetwork>
          <ShareNetwork
              class="twitter"
              network="twitter"
              url="https://ussg55inphotos.com"
              title='“From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos” – the virtual exhibition capturing highlights of the friendship, cooperation, and inspiration between the United States and Singapore through 55 photos.'
              hashtags="USSG55"
          ><img src="~@/assets/share-tw-dark.svg">
          </ShareNetwork>
          <a class="email" target="_blank" href='mailto:?subject=From Sea to Shining Sea: 55 Years of U.S.-Singapore Relations in Photos&body=Americans and Singaporeans have played and competed in sports, enjoyed and created music and art together, and inspired each other&#39;s views of the world.%0D%0ACheck out “From Sea to Shining Sea: 55 years of U.S. - Singapore relations in photos” – the virtual exhibition capturing highlights of the friendship, cooperation, and inspiration between the United States and Singapore through 55 photos. https://ussg55inphotos.com #USSG55'><img src="~@/assets/share-mail-dark.svg"></a>
          <div class="clear-float"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CultureInAction",
  methods: {
    goBack() {
      this.$router.push('explore');
    }
  }
}
</script>

<style scoped>

</style>