//first.js
const popup = {
    'r1c6': {
        popupV: false,
        popupImg: '2006-George-W-Bush-Wife-Gamelan-Orchestra.jpg',
        popupCaption: `<p>U.S. President George W. Bush (in suit) and First Lady Laura Bush (extreme right) tried their hand at gamelan instruments at the Asian Civilisations Museum. They were visiting Singapore en route to the 2006 APEC Summit in Vietnam. This was President Bush’s second visit to Singapore.</p><p>Image credit: Official White House photo by Paul Morse</p>`,
    },
    'r2c2': {
        popupV: false,
        popupImg: '1951-USIS-Raffles-Place.jpg',
        popupCaption: `<p>When Singapore was proclaimed a City of the British Commonwealth by royal charter on September 22, 1951, the U.S. Information Service (USIS) commemorated the occasion with a banner outside its offices at 26 Raffles Place. At that time, the USIS had a library and reading room and hosted events that introduced the public to American culture.</p><br><p>Image credit: U.S. Embassy Singapore</p>`,
    },
    'r2c3': {
        popupV: false,
        popupImg: '1980-Paul-Rudolph-Concourse.jpg',
        popupCaption: `<p>Paul Rudolph was a prominent modernist American architect who explored scale and modularity in his designs. In the 1980s, he designed the Colonnade, a condominium at Grange Road, and the Concourse (pictured), an office and shopping complex at Beach Road. For the latter, Rudolph used atriums and balconies to create a distinctive silhouette. The curtain wall pictured was rebuilt to the likeness of Rudolph’s design for the Concourse.</p><br><p>Image credit: Darren Soh</p><br><br><p>Learn more about Paul Rudolph’s design for the <a href="https://www.paulrudolphheritagefoundation.org/198104-office-and-shopping-complex" target="_blank">Concourse</a> and the <a href="https://www.paulrudolphheritagefoundation.org/198001-colonnade" target="_blank">Colonnade</a></p>`,
    },
    'r2c6': {
        popupV: false,
        popupImg: '1964-Louis-Armstrong.jpg',
        popupCaption: `<p>In November 1964, American jazz musician extraordinaire Louis Armstrong performed in Singapore. This photo with local jazz lovers was taken at a reception in his honor and includes Jeremy Soliano, Tony Castillo, Billy Martinez, Rufino Soliano, Jimmy Gan, and Horace Wee.</p><br><p>Image credit: U.S. Embassy Singapore</p>`,
    },
    'r2c7': {
        popupV: false,
        popupImg: '1966-US-Embassy.jpg',
        popupCaption: `<p>The former U.S. Embassy building at 30 Hill Street was completed in 1961. It was one of the first buildings in Singapore to be completely air‐conditioned. Built as a diplomatic facility, it was designated the first official U.S. Embassy here when the two countries established formal diplomatic relations on April 4, 1966.</p><br><p>Image credit: Jim and Junia Baker</p>`,
    },
    'r2c9': {
        popupV: true,
        popupImg: 'Jordi-Fornie-People-of-Singapore.jpg',
        popupCaption: `<p>Jordi Fornies, <span class="italic">People of Singapore</span> (2015)</p><p>In 2015, students at LASALLE College of the Arts exhibited new artworks responding to George Porter’s photographs. Fornies’s work was inspired by Porter’s “Young scholars.” It features Polaroid images of people in Singapore on a blackboard and projected on a door. It is accompanied by a soundtrack created by Fornies, using adjectives people used to describe Singapore.</p><br><p>Image credit: LASALLE College of the Arts</p>`,
    },
    'r2c10': {
        popupV: true,
        popupImg: 'George-Porter-Family-in-MG-1967.jpg',
        popupCaption: `<p>George Porter (at the wheel) was an American diplomat at the U.S. Embassy Singapore from 1965 to 1970. During his lunch hours and leisure time, he took numerous photographs of everyday life. This family photo in their MG (a British car) was most likely taken on the grounds of Spring Grove, the U.S. Ambassador’s residence.</p><br><p>Image credit: The family of George Porter</p>`,
    },
    'r3c3': {
        popupV: false,
        popupImg: '1950-Ford-Factory.jpg',
        popupCaption: `<p>Ford Motor Company, the American automaker that modernized assembly-line manufacturing, began producing its signature Model T cars in Singapore in 1926. In 1941, it opened its first purpose-built motorcar assembly plant in Southeast Asia at Upper Bukit Timah Road (pictured here in the 1950s). Except for the interruption of World War II, Ford operated this plant until 1980, producing more than 150,000 vehicles.</p><br><p>Courtesy of the National Museum of Singapore, National Heritage Board</p>`,
    },
    'r3c4': {
        popupV: false,
        popupImg: '2014-CARAT.jpg',
        popupCaption: `<p>In this photo from 2014, Lieutenant (junior grade) Katherine Smith (extreme left) on the guided-missile destroyer USS Wayne E. Meyer discussed its electrical plant and propulsion controls with engineering personnel from the Republic of Singapore Navy (RSN). This took place during Cooperation Afloat Readiness and Training (CARAT), an annual bilateral exercise with the U.S. Navy, U.S. Marine Corps, and the armed forces of nine partner nations, including Singapore. </p><br><p>Photo courtesy of the U.S. Department of Defense</p>`,
    },
    'r3c6': {
        popupV: true,
        popupImg: 'Tan-Luo-Yi-Hoard.jpg',
        popupCaption: `<p>Tan Luo Yi, <span class="italic">Hoard</span> (2015)</p><p>In 2015, students at LASALLE College of the Arts exhibited new artworks responding to George Porter’s photographs. Tan was inspired by Porter’s “The yellow bird” and “Daydreaming shop girl.” The work is an installation of found objects. It highlights the visually cluttered shops from the past, as captured in the photos.</p><br><p>Image credit: LASALLE College of the Arts</p>`,
    },
    'r3c7': {
        popupV: false,
        popupImg: 'Young-Scholars-February-1970.jpg',
        popupCaption: `<p>“Young scholars” (February 1970)</p><p>This photograph of primary school students was taken by George Porter, an American diplomat at the U.S. Embassy Singapore from 1965 to 1970. The location and identity of the students is unknown.</p><br><p>Image credit: George W. Porter Collection, courtesy of National Archives of Singapore</p>`,
    },
    'r3c8': {
        popupV: false,
        popupImg: '1974-Buckminister-Fuller.jpg',
        popupCaption: `<p>In October 1974, American architect and inventor Buckminster Fuller (standing, fourth from right) visited Singapore. Fuller was known for his creativity and for popularizing the geodesic dome as an architecturally robust design. In Singapore, he met local architects and designers at Alpha Gallery and at a miniature geodesic dome (pictured).</p><br><p>Image credit: Architects Team 3, R. Buckminster Fuller. 'Slides, visit to Singapore by R. Buckminster Fuller', October 1974. colour slide, 35 items. M+, Hong Kong. Gift of Architects Team 3, 2015. [CA29/4/4]. © Architects Team 3 Pte Ltd, Singapore.</p>`,
    },
    'r3c9': {
        popupV: false,
        popupImg: '2021-VP-Harris-Istana.jpg',
        popupCaption: `<p>U.S. Vice President Kamala Harris made her first official visit to Singapore on August 22–23, 2021. She met Singapore President Halimah Yacob, and, with Prime Minister Lee Hsien Loong, announced new partnerships to address the climate crisis, cybersecurity, and growth and innovation. At the Istana, Vice President Harris also received a hybrid orchid named after her, <span class="italic">Papilionanda Kamala Harris</span> (pictured).</p><br><p>Image credit: Official White House photo by Lawrence Jackson</p><br><br><p>Catch up on <a href="https://sg.usembassy.gov/vp-harris-trip-singapore-vietnam/" target="_blank">the highlights of Vice President Harris’s visit</a> to Singapore</p><br><br><p>Learn more about <a href="https://www.channelnewsasia.com/watch/us-singapore-relations-55/us-singapore-relations-55-2128461" target="_blank">55 years of bilateral relations between the United States and Singapore</a> in this documentary</p>`,
    },
    'r3c10': {
        popupV: false,
        popupImg: '1955-60-Athletics.jpg',
        popupCaption: `<p>American sports coach Thomas Rosandich (in the USAF jersey) coached Singapore’s pole vaulter Pal Singh at the Padang in Singapore in April 1958. The U.S. Information Service (USIS) invited Rosandich to conduct workshops and coaching clinics here. He went on to be the founding president of the United States Sports Academy.</p><br><p>Image credit: U.S. Embassy Singapore</p>`,
    },
    'r3c11': {
        popupV: false,
        popupImg: '1965-Edward-Kennedy.jpg',
        popupCaption: `<p>In 1965, U.S. Senator Edward Kennedy (center) spoke with student leaders from Singapore’s institutions of higher learning at the University of Singapore Students Union building. Kennedy was chairman of a Congressional subcommittee studying the refugee problem in Asia.</p><br><p>Source: The Straits Times © SPH Media Limited. Permission required for reproduction.</p>`,
    },
    'r4c3': {
        popupV: true,
        popupImg: 'June-Chua-Stonewall-Inn-IVLP.jpg',
        popupCaption: `<p>June Chua from the T Project in Singapore participated in the U.S. Department of State’s International Visitor Leadership Program (IVLP) in 2018. The IVLP brings current and emerging foreign leaders to the United States each year for short-term professional exchanges with their American counterparts. Madam Halimah Yacob, Singapore’s first female President, is an IVLP alumna.</p><br><p>Image credit: June Chua, the T Project</p>`,
    },
    'r4c4': {
        popupV: false,
        popupImg: 'Daydreaming-Shop-Girl-October-1969.jpg',
        popupCaption: `<p>“Daydreaming girl in a shop“ (October 1969)</p><p>This photograph was taken by George Porter, an American diplomat at the U.S. Embassy Singapore from 1965 to 1970. The location and identity of the woman is unknown. The photograph might have been taken near the U.S. Embassy Singapore in Hill Street, which was close to the High Street shopping district that was popular at the time.</p><br><p>Image credit: George W. Porter Collection, courtesy of National Archives of Singapore</p>`,
    },
    'r4c5': {
        popupV: true,
        popupImg: '1977-Mickey-Minnie-Mouse.jpg',
        popupCaption: `<p>Disneyland’s Mickey and Minnie Mouse visited Singapore to celebrate the United States’ 201st Independence Day with the American community. Accompanying them was Disneyland’s 1977 ambassador, Susan Donald (center). Besides appearing at the Singapore American School, the mascots also brought good cheer to hospitals, welfare homes for children, and the Singapore Association for the Deaf.</p><br><p>Source: The Straits Times © SPH Media Limited. Permission required for reproduction.</p>`,
    },
    'r4c6': {
        popupV: true,
        popupImg: 'Margaret-Leng-Tan.jpg',
        popupCaption: `<p>Singaporean musician Margaret Leng Tan was the first woman to earn a doctorate from the Juilliard School in New York in 1971. She collaborated with her mentor, the leading American avant-garde composer John Cage, from 1981 until his death in 1992. A Singapore Cultural Medallion and US National Endowment for the Arts award recipient, Tan continues to break new ground in experimental music, using toy pianos and incorporating theater and choreography in her work.</p><br><p>Image credit: Michael Dames</p><br><br><p>Watch <a href="https://www.youtube.com/watch?v=aaZGMb6kPX8" target="_blank">Tan’s performance at the National Gallery Singapore</a> in 2021</p>`,
    },
    'r4c8': {
        popupV: true,
        popupImg: 'The-Tiller-and-the-Thinker-1960s.jpg',
        popupCaption: `<p>“The tiller and the thinker” (1960s)</p><p>This photograph was taken by George Porter, an American diplomat at the U.S. Embassy Singapore from 1965 to 1970. It was taken at the Singapore River. The boats, known in Hokkien and Teochew as twakow, and in Malay as tongkang, were used to transport cargo from ships in the harbor to warehouses or vehicles along the river.</p><br><p>Image credit: George W. Porter Collection, courtesy of National Archives of Singapore</p>`,
    },
    'r4c9': {
        popupV: false,
        popupImg: 'Hoe-Juan-Sim-SAS.jpg',
        popupCaption: `<p>The Ho family (also spelled “Hoe”) has been running the dining service at the Singapore American School since 1965. They serve both local and American food. School alumni describe their curry, sweet and sour chicken, and Szechuan chicken as some of their fondest food memories in Singapore.</p><br><p>Image credit: The family of Ho Juan Sim</p><br><br><p>Read more about <a href="https://www.sas.edu.sg/about-us/publications-and-media/perspectives/post-details/~board/perspectives-blog/post/in-loving-memory-mr-ho-juan-sim" target="_blank">the Singapore American School’s loving memories of Mr. Ho Juan Sim</a></p>`,
    },
    'r4c10': {
        popupV: false,
        popupImg: '2012-Naval-Academy-Sam-Tan.jpg',
        popupCaption: `<p>In 2012, Republic of Singapore Navy officer Sam Tan Wei Shen topped his class at the U.S. Naval Academy. He was the first foreign midshipman to achieve this honor in the institution’s 167-year history.</p><br><p>Image credit: U.S. Naval Academy</p>`,
    },
    'r5c1': {
        popupV: false,
        popupImg: '1955-American-Club.jpg',
        popupCaption: `<p>The American Club in Singapore was founded in 1948 and moved to Scotts Road (pictured) in 1955. It was notable for being the first foreign private club in Singapore to open its membership to all races and nationalities. Today it has more than 10,000 members from around the world. </p><br><p>Image credit: Jim and Junia Baker</p><br><br><p>Read about some highlights of the <a href="https://www.amclub.org.sg/news-info/committee/club-history.html" target="_blank">club’s history</a></p><br><br><p>Take <a href="https://www.youtube.com/watch?v=0uIXh4dm6oc" target="_blank">a walk down memory lane</a> with club member Jim Baker</p>`,
    },
    'r5c2': {
        popupV: true,
        popupImg: '1990-IM-Pei-Gateway.jpg',
        popupCaption: `<p>I.M. Pei (full name Ieoh Ming Pei) was a modernist American architect, perhaps best known for designing the Louvre Pyramid. In Singapore, his buildings have become iconic landmarks: OCBC Centre at Chulia Street, Raffles City, and the Gateway (pictured). The latter, like many of his works, is noted for its precise geometry.</p><br><p>Image credit: Darren Soh</p><br><br><p>Learn more about <a href="https://www.youtube.com/watch?v=KmdnmpRZlDE" target="_blank">I.M. Pei’s buildings</a> in Singapore</p>`,
    },
    'r5c3': {
        popupV: false,
        popupImg: '1965-Man-In-Space.jpg',
        popupCaption: `<p>In April 1965, the U.S. Information Service (USIS) held an exhibition, “U.S. Man in Space,” in the foyer of the National Library at Stamford Road. On display was a model of the Gemini spacecraft, which had the previous month made its first successful crewed flight carrying two American astronauts to space.</p><br><p>Image credit: U.S. Embassy Singapore</p><br><br><p>Did you know that <a href="https://www.science.edu.sg/whats-on/exhibitions/past-exhibitions/moonshot-50" target="_blank">the Singapore Science Centre has a moon rock</a> from the Apollo 11 landing?</p><br><br><p>Hear from <a href="https://www.youtube.com/playlist
?list=PL64pfLZ8XYrGfRPuaJuPPY4I9pJ3D377N" target="_blank">Singaporeans who remember watching the Moon landing</a> in 1969</p>`,
    },
    'r5c4': {
        popupV: true,
        popupImg: 'Mohd-Yafaridah-Spring-Grove.jpg',
        popupCaption: `<p>A bungalow along Grange Road named Spring Grove was home to American Consuls-General, Ambassadors, and their staff from 1936 to 1991. It hosted Independence Day receptions and Easter egg hunts for the American community. This photograph from 1974 shows Mohd Yafaridah and her older sister. Their father worked as a chauffeur for the U.S. government. Yafridah herself has been working at the Embassy for 28 years.</p><br><p>Image credit: Mohd Yafaridah</p><br><br><p>Learn more about <a href="https://sg.usembassy.gov/humans-of-the-embassy-mohd-yafaridah-financial-management-office/" target="_blank">Mohd Yafaridah’s connection</a> with Spring Grove</p>`,
    },
    'r5c5': {
        popupV: false,
        popupImg: '2003-GohChokTongGWBush-FTA.jpg',
        popupCaption: `<p>On May 6, 2003, U.S. President George Bush (seated, right) and Singapore Prime Minister Goh Chok Tong (seated, left) signed the U.S.-Singapore Free Trade Agreement (FTA). Singapore was the first country in Asia with which the United States signed a FTA. It is the longest such agreement the latter has in Asia. The trade relationship between the two countries has doubled since the FTA came into force on January 1, 2004.</p><br><p>Image credit: The White House, photo by Tina Hager</p><br><br><p>Watch <a href="https://www.youtube.com/watch?v=prUPNTYaKeo" target="_blank">the historic signing of the FTA</a> in 2003</p><br><br><p>Learn more about <a href="https://www.channelnewsasia.com/watch/us-singapore-relations-55/us-singapore-relations-55-2128461" target="_blank">55 years of bilateral relations between the United States and Singapore</a> in this documentary</p>`,
    },
    'r5c6': {
        popupV: false,
        popupImg: '1960-San-Francisco-Ballet.jpg',
        popupCaption: `<p>In 1960, the San Francisco Ballet performed at the Victoria Theatre, an event described as Singapore’s “cultural event of the year.” Five members of the Ballet are pictured here posing for TV and publicity pictures at Queen Elizabeth Walk, with the former Fullerton Building (now Fullerton Hotel) in the background.</p><br><p>Image credit: U.S. Embassy Singapore</p>`,
    },
    'r5c7': {
        popupV: false,
        popupImg: '1973-Boeing-SIA.jpg',
        popupCaption: `<p>In 1973, the nascent Singapore Airlines (SIA) received two B747s, its first wide-bodied aircraft, from Boeing, the American multinational aerospace company. Pictured are SIA flight attendants practicing in-flight service during a simulated flight in a real Boeing 747. Over the years, SIA has ordered more than 200 Boeing airplanes. Today, Boeing’s Southeast Asia headquarters is located in Singapore.</p><br><p>Source: The Straits Times © SPH Media Limited. Permission required for reproduction.</p><br><br><p>Watch <a href="https://www.youtube.com/watch?v=Mg_wbrEDQys" target="_blank">a TV advertisement for SIA’s first B747-200s</a> from the 1970s</p>`,
    },
    'r5c8': {
        popupV: false,
        popupImg: '2017-George-Washington-Ball.jpg',
        popupCaption: `<p>The George Washington Centennial Ball (pictured here in 2017) has been the annual highlight of the American Association of Singapore calendar since 1933. The association, founded in 1917, brings the local American community together through annual events such as the largest Independence Day Celebration in Singapore and the Turkey Trot run at Thanksgiving.</p><br><p>Image credit: Tatyana Kildisheva</p><br><br><p>
Learn about the Association's <a href="https://www.aasingapore.com/aas-histor
y" target="_blank">more than hundred-year history</a>.</p>`,
    },
    'r5c10': {
        popupV: false,
        popupImg: '2016-White-House-State-Dinner.jpg',
        popupCaption: `<p>U.S. President Barack Obama (extreme right) and First Lady Michelle Obama (in white) entering the state dinner held on August 2, 2016 for Singapore Prime Minister Lee Hsien Loong (second from right) and his wife Ho Ching (in red). This was the first state dinner hosted by the White House for Singapore and marked fifty years of diplomacy between the two countries.</p><br><p>Image credit: Ministry of Communications and Information Collection, courtesy of National Archives of Singapore</p><br><br><p>Watch the <a href="https://www.youtube.com/watch?v=FyOtXHQwj60" target="_blank">arrival ceremony for Prime Minister Lee</a> at the start of his official visit to the White House in 2016</p><br><br><p>Check out <a href="https://obamawhitehouse.archives.gov/sites/default/files/
image/files/singaporestatedinner.pdf" target="_blank">the menu and other details</a> for the state dinner</p>`,
    },
    'r6c2': {
        popupV: true,
        popupImg: '1973-Harlem-Globetrotters.jpg',
        popupCaption: `<p>The Harlem Globetrotters, an American basketball team, made their first visit to Singapore in 1952, delighting audiences with their brand of sporting flair and entertainment style. They played four games before an audience of about ten thousand and returned a number of times in subsequent decades. This photograph was taken in 1973 at Gay World Stadium.</p><br><p>Source: The Straits Times © SPH Media Limited. Permission required for reproduction.</p><br><br><p>Watch <a href="https://www.razor.tv/video/harlem-globetrotters-thrill-fans-in-singapore/4800266888001/6022787022001" target="_blank">the Harlem Globetrotters play</a> the Washington Generals in Singapore in 2019</p>`,
    },
    'r6c3': {
        popupV: false,
        popupImg: '1997-Madeline-Albright.jpg',
        popupCaption: `<p>The first female U.S. Secretary of State Madeline Albright (in wide-brimmed hat) arrived in Singapore on a stopover in 1997. She met then Senior Minister Lee Kuan Yew, as well as visited the Revere Bell at the National Museum. She returned in 1999 to attend the ASEAN Regional Forum and ASEAN Post-Ministerial Conference.</p><br><p>Image credit: U.S. Embassy Singapore</p>`,
    },
    'r6c5': {
        popupV: false,
        popupImg: '1956-Singapore-American-School.jpg',
        popupCaption: `<p>The Singapore American School opened on January 3, 1956 at 15 Rochalie Drive (pictured), with 98 elementary and junior high students. It is now located in Woodlands and has more than 4,000 students. It is the largest single-campus American curriculum school outside the United States. Notable alumni include U.S. Senator Tammy Duckworth and actor Julia Nickson-Soul.</p><br><p>Image credit:  Ministry of Information and the Arts Collection, courtesy of National
Archives of Singapore</p><br><br><p>Learn more about the <a href="https://www.sas.edu.sg/about-us/the-sas-story" target="_blank">history</a> of the school</p>`,
    },
    'r6c6': {
        popupV: true,
        popupImg: 'Money-Changer-November-1969.jpg',
        popupCaption: `<p>“Money changer” (November 1969)</p><p>This photograph was taken by George Porter, an American diplomat at the U.S.Embassy Singapore from 1965 to 1970. The sundry store pictured also offers money changing services. The shopkeeper is wearing a sarong, which used to be more common attire among South Indian men.</p><br><p>Image credit: George W. Porter Collection, courtesy of National Archives of Singapore</p>`,
    },
    'r6c7': {
        popupV: false,
        popupImg: '2009-Obama-Clinton-Lee-Kuan-Yew-APEC-Event.jpg',
        popupCaption: `<p>U.S. President Barack Obama (center) with Singapore’s Minister Mentor Lee Kuan Yew (right) and U.S. Secretary of State Hillary Rodham Clinton (seated) at a dinner for leaders attending the 2009 Asia-Pacific Economic Cooperation (APEC) meetings. The event was held in at APEC’s headquarters in Singapore.</p><br><p>Image credit: Official White House Photo by Pete Souza</p>`,
    },
    'r6c8': {
        popupV: false,
        popupImg: '1983-Revere-Bell.jpg',
        popupCaption: `<p>The National Museum of Singapore is home to the only Revere bell outside the United States. It was given by Maria Revere Balestier, the daughter of Paul Revere, to St. Andrew’s Church in Singapore in 1843 and later donated to the museum. In 1983, a U.S. Congressional delegation were briefed by museum curator Mrs. Eng Seok Chee (third from left) about its history.</p><br><p>Image credit: Ministry of Information and the Arts Collection, courtesy of National Archives of Singapore</p><br><br><p>Read more about <a href="https://www.facebook.com/National.Museum.of.
Singapore/photos/a.109197459140975/1111879515539426/" target="_blank">the Revere Bell in Singapore</a>.</p><br><br><p>Hear <a href="https://www.youtube.com/watch?v=2KhUAipy6_I" target="_blank">what a Revere bell sounds like</a>.</p>`,
    },
    'r6c9': {
        popupV: true,
        popupImg: 'Victor-Savage-Fulbright.jpg',
        popupCaption: `<p>Dr. Victor Savage (standing) and Dr. Ann Pakir (seated, extreme left) are among more than 500 Singaporeans who have received Fulbright scholarships. Drs. Savage and Pakir pursued their PhDs at the University of California, Berkeley in the late 1970s and had distinguished careers at universities in Singapore. The 75-year-old Fulbright Program aims to increase mutual understanding and friendly relations between people of the U.S. and other countries.</p><br><p>Image credit: Victor Savage</p><br><br><p>Hear more about <a href="https://www.youtube.com/watch?v=y-AL8p95ExM" target="_blank">Dr. Savage’s Fulbright experience</a>.</p><br><br><p>Check out <a href="https://www.youtube.com/watch?v=m5eG_UyqoG0
&list=PL64pfLZ8XYrESyqJ9p3qoFtEs3BNucRjj" target="_blank">more stories from Fulbright Scholars from Singapore</a>.</p>`,
    },
    'r6c10': {
        popupV: false,
        popupImg: '1972-Duke-Ellington.jpg',
        popupCaption: `<p>The great American jazz musician Duke Ellington gave a one-night only performance at the National Theatre in Singapore on February 2, 1972. He began his career in the 1920s and composed more than a thousand compositions, many of which have become jazz standards. The Duke Ellington Orchestra, which he founded, still performs today and last played in Singapore in 2018 at the Singapore International Festival of the Arts.</p><br><p>Source: The Straits Times © SPH Media Limited. Permission required for reproduction.</p><br><br><p>Read about <a href="https://eresources.nlb.gov.sg/newspapers/
Digitised/Article/newnation19720203-1.2.26" target="_blank">the Duke’s performance in Singapore</a> in 1972</p><br><br><p>Check out some photos of <a href="https://twitter.com/redwhitebluedot/status/996275613378994177?lang=en" target="_blank">the Duke Ellington Orchestra’s performance in Singapore</a> in 2018</p>`,
    },
    'r6c11': {
        popupV: false,
        popupImg: '2010-Valiant-Mark.jpg',
        popupCaption: `<p>Held annually since 1991, Exercise Valiant Mark is conducted by the U.S. Marine Corps and the Singapore Armed Forces (SAF). This picture, taken in 2010, shows U.S. Marines from Company A “Animal,” 1st Battalion 7th Marines along with soldiers from 1st Battalion Singapore Guards assaulting an “enemy” compound at the Murai Urban Training Facility in Singapore.</p><br><p>Photo courtesy of the U.S. Department of Defense</p>`,
    },
    'r7c3': {
        popupV: false,
        popupImg: '1973-Muhammad-Ali.jpg',
        popupCaption: `<p>The legendary American boxer Muhammad Ali gave two exhibition bouts against his sparring partners at Singapore’s newly built National Stadium on October 24, 1973. Ali was known for becoming the world heavyweight champion in 1964, then converting to Islam. In 2005, he visited Singapore for the International Olympic Committee meeting. He was part of the U.S. delegation supporting New York City’s bid to host the 2012 Olympic Games. </p><br><p>Source: The Straits Times © SPH Media Limited. Permission required for reproduction.</p><br><br><p>Read <a href="https://eresources.nlb.gov.sg/newspapers/Digitised/
Article/straitstimes19731023-1.2.133.13" target="_blank">Muhammad Ali’s poem “Truth”</a>, which he recited during his visit to Singapore in 1973</p><br><br><p>Learn more about <a href="https://www.forbes.com/sites/franklavin/2016/06/14/muhammad-alis-singapore-diplomatic-mission" target="_blank">Muhammad Ali’s visit to Singapore in 2005</a> from former U.S. Ambassador Frank Lavin</p>`,
    },
    'r7c4': {
        popupV: true,
        popupImg: 'The-Yellow-Bird-1965-1970.jpg',
        popupCaption: `<p>“The yellow bird” (1965–1970)</p><br><p>This photograph was taken by George Porter, an American diplomat at the U.S. Embassy Singapore from 1965 to 1970. The shop is selling colorful animal-shaped lanterns made of wire and cellophane. These were popular with children during the annual Mid-Autumn Festival (also known as the Mooncake Festival).</p><br><p>Image credit: George W. Porter Collection, courtesy of National Archives of Singapore</p>`,
    },
    'r7c5': {
        popupV: false,
        popupImg: '1969-Eartha-Kitt.jpg',
        popupCaption: `<p>American singer and actor Eartha Kitt performed for three nights at the National Theatre in Singapore in March 1969. In the 1950s, she became renowned for her distinctive recordings of the songs “C’est si bon” and “Santa Baby.” Her music was popular with young English-speaking Singaporeans and frequently played on local radio.</p><br><p>Source: The Straits Times © SPH Media Limited. Permission required for reproduction.</p><br><br><p>Read about <a href="https://eresources.nlb.gov.sg/newspapers/
Digitised/Article/straitstimes19690307-1.2.136" target="_blank">Eartha Kitt’s interview with the press</a> in Singapore</p>`,
    },
    'r7c7': {
        popupV: false,
        popupImg: '2003-Lee-Wen-Strange-Fruit.jpg',
        popupCaption: `<p>In 2016, the U.S. Embassy Singapore awarded the Joseph Balestier Award for the Freedom of Art to Singaporean artist Lee Wen. The award is for artists who “push the boundaries of creativity and challenge constraints to freedom of expression.” Lee was a pioneering performance artist who was critically well-regarded for his <span class="italic">Yellow Man</span> works (1992–2001). Pictured is <span class="italic">Strange Fruit</span> (2003).</p><br><p>Image courtesy of the artist and National Heritage Board</p><br><br><p class="disclaimer">Disclaimer: The copyright of the artwork belongs to the artist and their authorised representatives and may not be reproduced for any purpose whatsoever without the prior written permission of the National Heritage Board and/or copyright owners.</p><br><br><p>Explore <a href="https://aaa.org.hk/en/collections/search/archive/lee-wen-archive" target="_blank">the artworks and career of Lee Wen</a></p><br><br><p>Learn more about <a href="https://eresources.nlb.gov.sg/infopedia/articles/
SIP_951_2004-12-23.html
" target="_blank">Joseph Balestier, the first American consul to Singapore</a></p>`,
    },
    'r7c8': {
        popupV: false,
        popupImg: 'Hand-in-Hand-1968.jpg',
        popupCaption: `<p>“Hand-in-hand” (1968)</p><br><p>This photograph was taken by George Porter, an American diplomat at the U.S. Embassy Singapore from 1965 to 1970. The children are walking by a sundry store, known colloquially as a mamak shop (mamak means “uncle” in Tamil). These shops used to be a common sight in Singapore but are rare in the downtown area today.</p><br><p>Image credit: George W. Porter Collection, courtesy of National Archives of Singapore</p>`,
    },
    'r7c10': {
        popupV: false,
        popupImg: '2013-Google.jpg',
        popupCaption: `<p>In the internet age, American technology companies continue to have a strong presence in Singapore. For example, Singapore is currently Google’s Asia Pacific headquarters, and the company is building its third data center here, which will be one of the most efficient and environmentally friendly sites in Asia. The first, which opened in 2013, is pictured.</p><br><p>Image credit: Google Singapore</p>`,
    },
    'r8c2': {
        popupV: true,
        popupImg: 'Sonny-Liew.jpg',
        popupCaption: `<p>Artist Sonny Liew was the first Singaporean to win three Eisner awards, widely regarded as the Oscars of the comic world. In 2017, he won for Best Writer/Artist, Best Publication Design, and Best U.S. Edition of International Material in Asia for his groundbreaking graphic novel, <span class="italic">The Art of Charlie Chan Hock Chye</span>.</p><br><p>Image credit: Crispian Chan</p>`,
    },
    'r8c3': {
        popupV: true,
        popupImg: '1923-Citibank-SG-Ocean-Building.jpg',
        popupCaption: `<p>Citi has operated in Singapore since 1902, when its predecessor company, the International Banking Corporation, became the first American bank to open here. It focused on financing tin and rubber exports from the Malay Peninsula. Pictured is its Ocean Building premises in 1923, which was demolished in 1970. Renamed Citibank in 1976, Citi Singapore is now one of the largest bank employers in Singapore.</p><br><p>Image credit: Citi Singapore</p><br><br><p>Learn more about <a href="https://www.youtube.com/watch?v=2JdsstYPJKQ
&list=PL64pfLZ8XYrG4kJVi8uiHShQaW8NOdHQ-" target="_blank">how American companies are making a difference</a> in Singapore</p>`,
    },
    'r8c4': {
        popupV: false,
        popupImg: '1950s-USIS-Library.jpg',
        popupCaption: `<p>The first U.S. Information Service (USIS) offices and library in Singapore were located on the second floor of 26 Raffles Place. This photograph shows the reading room in the 1950s, when it was popular with young people who wanted access to American publications or to learn more about the United States.</p><br><p>Image credit: U.S. Embassy Singapore</p>`,
    },
    'r8c5': {
        popupV: false,
        popupImg: '1980-Apple-Factory.jpg',
        popupCaption: `<p>Some U.S. technology companies that invested heavily in Singapore’s high-tech manufacturing sector in the 1980s were Hewlett-Packard, Micron, and Apple. The iconic Apple Macintosh personal computer was assembled at the company’s factory at Ang Mo Kio in the late 1980s (pictured). Today, there are 4,500 U.S. companies in Singapore, employing about 200,000 people.</p><br><p>Image credit: Ministry of Information and the Arts Collection, courtesy of National Archives of Singapore</p><br><br><p>Learn more about <a href="https://www.youtube.com/watch?v=uV6RZJjZOEc" target="_blank">Hewlett-Packard’s corporate culture</a> in Singapore</p>`,
    },
    'r8c6': {
        popupV: false,
        popupImg: 'Christopherson-Ho-Memories-of-Lunar.jpg',
        popupCaption: `<p>Christopherson Ho, <span class="italic">Memories of lunar</span> (2015)</p><p>
In 2015, students at LASALLE College of the Arts exhibited new artworks responding to George Porter’s photographs. Ho responded to Porter’s “The yellow bird” and was further inspired by Ho’s own memories of celebrating the Mid-Autumn Festival. The installation consists of 20 ceramic pieces made in the shape of lanterns, suspended from bamboo poles.
</p><br><p>Image credit: LASALLE College of the Arts</p>`,
    },
    'r8c7': {
        popupV: false,
        popupImg: '2019-Valiant-Mark.jpg',
        popupCaption: `<p>A member of the Singapore Armed Forces introduced a U.S. Marine to a durian during Exercise Valiant Mark 2019 on Pulau Tekong in Singapore. Singapore and the United States have a strong bilateral defense partnership. For example, 2021 marked the 40th year of Exercise Tiger Balm between the two countries.</p><br><p>Photo courtesy of the U.S. Department of Defense</p><br><br><p>Learn more about <a href="https://sg.usembassy.gov/fact-sheets/" target="_blank">defense cooperation between the U.S. and Singapore</a></p>`,
    },
    'r8c9': {
        popupV: false,
        popupImg: '2021-RSAF-Nellis-Air-Force-Base.jpg',
        popupCaption: `<p>More than 1,000 Singaporean military personnel participate in training, exercises, and professional military education in the United States. Pictured are Republic of Singapore Air Force (RSAF) pilots and contractor who participated in the Red Flag exercise at Nellis Air Force Base in Nevada in 2021. They trained in advanced aerial combat scenarios with the U.S. Air Force, Sweden, and several NATO nations.</p><br><p>Photo courtesy of the U.S. Department of Defense</p><br><br><p>Learn more about <a href="https://www.youtube.com/watch?v=50V1JQp19NE" target="_blank">the RSAF’s Air Force Engineer team</a> that clinched the “Outstanding Maintenance Unit” award at Red Flag in 2021</p><br><br><p>Watch the <a href="https://www.dvidshub.net/video/548539/428th-fighter-squadron-participates-red-flag-17-4-w-o-graphics" target="_blank">428th fighter squadron</a>, which trains RSAF pilots, participate in Red Flag in 2021</p>`,
    },
    'r8c10': {
        popupV: true,
        popupImg: '1985-Lee-Kuan-Yew-Ronald-Reagan.jpg',
        popupCaption: `<p>As Singapore’s Prime Minister, Lee Kuan Yew made three state visits to the White House in 1973, 1975, and 1985. Each time, he was honored with a state dinner. Pictured here is Prime Minister Lee (left) with President Ronald Reagan (right) in the Diplomatic Reception Room at the White House during the 1985 visit.</p><br><p>Image credit: Ronald Reagan Presidential Library and Museum/National Archives and Records Administration</p><br><br><p>Watch the <a href="https://www.youtube.com/watch?v=uZFYpQUAoSk" target="_blank">arrival ceremony for Prime Minister Lee</a> at the start of his official visit to the White House in 1985</p>`,
    },
    'r9c6': {
        popupV: false,
        popupImg: '1962-Zubir-Said-New-York-Woodwind-Quintet.jpg',
        popupCaption: `<p>Zubir Said (right), the composer of Singapore’s national anthem, “Majulah Singapura,” discussed a local bamboo musical instrument with a member of the New York Woodwind Quintet. The group performed at the Victoria Memorial Hall in Singapore in September 1962 at an event sponsored by the U.S. Information Service (USIS).</p><br><p>Image credit: U.S. Embassy Singapore</p>`,
    },
};

module.exports = { popup };